import React, { useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import playgroundFloating from 'assets/images/playground_floating.png';
import Loading from 'components/atoms/Loading';
import Footer from 'components/organisms/Footer';
import Header, { ActionTypes } from 'components/organisms/Header';
import useCheckOtherTab from 'hooks/useCheckOtherTab';
import useLogout from 'hooks/useLogout';
import useSocket from 'hooks/useSocket';
import { useAppSelector } from 'store/hooks';
import { CONSTANT_ROUTES } from 'utils/constants';
import { getPathName } from 'utils/language';
import groupMenus from 'utils/menus';

const Mainlayout: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { footer, header } = useAppSelector((state) => state.systems);
  const { profile } = useAppSelector((state) => state.auth);
  const { list } = useAppSelector((state) => state.menus);

  const { onHandleLogout, onHandleBeforeLogout, isLoadingLogout } = useLogout();
  useSocket();
  useCheckOtherTab();

  const menuFooter = useMemo(() => {
    const footerMenu = list.find((item) => item.code === footer?.blocks?.section?.menu?.data)
      ?.items;
    if (footerMenu && footerMenu.length > 0) {
      return groupMenus(footerMenu) || [];
    }
    return [];
  }, [footer, list]);

  const handleLogout = useCallback(() => {
    if (pathname.includes(CONSTANT_ROUTES.WORKSPACE)
      && profile?.customerData.usingServer === 'starting') {
      onHandleBeforeLogout();
    } else {
      onHandleLogout();
    }
  }, [onHandleBeforeLogout, onHandleLogout, pathname, profile?.customerData.usingServer]);

  // const dataSocials = useMemo(() => footer?.blocks?.section?.socials?.data?.map((item) => ({
  //   imgSrc: item.icon.data.path,
  //   href: item?.link?.data?.url || '',
  //   target: item?.link?.data?.target || '',
  // })), [footer?.blocks?.section?.socials?.data]);
  const actionList: ActionTypes[] = useMemo(() => [
    {
      icon: 'ai',
      iconActive: 'aiActive',
      title: 'Workspace',
      pathname: getPathName('WORKSPACE'),
      handleClick: () => navigate(getPathName('WORKSPACE'))
    },
    {
      icon: 'profile',
      iconActive: 'profileActive',
      title: 'My profile',
      pathname: getPathName('PROFILE'),
      handleClick: () => navigate(getPathName('PROFILE'))
    },
    {
      icon: 'ordered',
      iconActive: 'orderedActive',
      title: 'Order',
      pathname: getPathName('ORDER'),
      handleClick: () => navigate(getPathName('ORDER'))
    },
    {
      icon: 'billingInfo',
      iconActive: 'billingInfoActive',
      title: 'Billing Information',
      pathname: getPathName('BILLING_INFO'),
      handleClick: () => navigate(getPathName('BILLING_INFO'))
    },
    {
      icon: 'history',
      iconActive: 'historyActive',
      title: 'History',
      pathname: getPathName('HISTORY'),
      handleClick: () => navigate(getPathName('HISTORY'))
    },
    {
      icon: 'logout',
      iconActive: 'logout',
      title: 'Log out',
      pathname: '#',
      handleClick: handleLogout
    }
  ], [navigate, handleLogout]);

  return (
    <>
      <Header
        logoSrc={header?.blocks?.section?.logoSrc?.data?.path}
        userInfo={{
          email: profile?.customerData?.email,
          name: profile?.customerData?.name
        }}
        actionList={actionList}
        isLogin={!!profile}
      />
      <main>
        <Outlet />
      </main>
      <Footer
        policyText={footer?.blocks?.section?.policyText}
        menu={menuFooter}
        address={footer?.blocks?.section.address}
        logo={footer?.blocks?.section.logo}
        licenseNo={footer?.blocks?.section.licenseNo}
        nameCompany={footer?.blocks?.section.nameCompany}
      // socials={dataSocials || []}
      />
      <div
        className="floating-playground"
        onClick={() => {
          navigate(getPathName('WORKSPACE'));
        }}
      >
        <img width={60} height={60} alt="floating playground" src={playgroundFloating} />
      </div>
      {isLoadingLogout && <Loading variant="fullscreen" />}
    </>
  );
};

export default Mainlayout;
