import { useEffect } from 'react';
import { useMutation } from 'react-query';

import { postCheckFocusService } from 'services/auth';
import { CheckFocusParamsTypes } from 'services/auth/types';
import { useAppSelector } from 'store/hooks';

const useCheckOtherTab = () => {
  const profile = useAppSelector((state) => state.auth.profile);

  const { mutate } = useMutation(
    ['postCheckFocusService'],
    (data: CheckFocusParamsTypes) => postCheckFocusService(data),
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (profile) {
        if (document.hidden) {
          mutate({
            isFocus: false
          });
        } else {
          mutate({
            isFocus: true
          });
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [mutate, profile?.customerData.email, profile?.customerData.phone]);
};

export default useCheckOtherTab;
