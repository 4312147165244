import React, { useEffect, useMemo, useState } from 'react';
import { Loading, Typography } from 'tfc-components';

import { getLocalStorage } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE } from 'utils/constants';

export interface CountdownTimerProps {
  handleEnded?: () => void;
  isTabTimer?: boolean;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  handleEnded,
  isTabTimer,
}) => {
  const { duration, status } = useAppSelector((state) => state.auth.aiServerResult);
  const isLoadingProcessService = useAppSelector((state) => state.auth.isLoadingProcessService);
  const titleSystemSEO = useAppSelector((state) => state.systems.system?.seoData?.title);

  const [timer, setTimer] = useState({
    hours: '--',
    minutes: '--',
    seconds: '--',
  });

  const [isEndTime, setIsEndTime] = useState(false);

  const format = (value: number): string => {
    if (value < 0) {
      return '00';
    }
    if (value < 10) {
      return `0${value}`;
    }
    return value.toString();
  };

  const remainingTime5Minutes = useMemo(() => {
    if (Number(timer.hours) === 0 && Number(timer.minutes) <= 5 && status === 'starting') {
      return true;
    }
    return false;
  }, [timer.hours, timer.minutes, status]);

  useEffect(() => {
    let time = duration || 0;
    const countdown = setInterval(() => {
      if (time >= 0) {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const second = format(time - hours * 3600 - minutes * 60);

        setTimer({
          hours: format(hours),
          minutes: format(minutes),
          seconds: second
        });
      }
      time -= 1;

      if (time < 0) {
        setTimer({ hours: '00', minutes: '00', seconds: '00' });
      }

      if (time < 0 || status !== 'starting') {
        clearInterval(countdown);
      }
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [status, duration, handleEnded]);

  useEffect(() => {
    if (isTabTimer && Number(timer.hours) === 0
      && Number(timer.minutes) === 0
      && Number(timer.seconds) === 0) {
      setIsEndTime(true);
    }
  }, [timer, isTabTimer]);

  useEffect(() => {
    const titleSEO = getLocalStorage(LOCAL_STORAGE.TITLE_CD) || titleSystemSEO || 'Cloud Diffusion';
    if (status === 'starting') {
      document.title = `${timer.hours}:${timer.minutes}:${timer.seconds}`;
    } else {
      document.title = titleSEO;
    }

    return () => {
      if (status === 'starting') {
        document.title = `${timer.hours}:${timer.minutes}:${timer.seconds}`;
      }
    };
  }, [status, timer, titleSystemSEO]);

  if (isLoadingProcessService && isTabTimer) return <Loading.CircleDashed width={30} />;

  return (
    <div className="m-countdownTimer fs-16 color-mediumPersianBlue">
      <div className="m-countdownTimer_item" style={{ color: isEndTime || remainingTime5Minutes ? '#E80000' : 'unset' }}><Typography.Text>{timer.hours}</Typography.Text></div>
      <div className="m-countdownTimer_separate" style={{ color: isEndTime || remainingTime5Minutes ? '#E80000' : 'unset' }}><Typography.Text>:</Typography.Text></div>
      <div className="m-countdownTimer_item" style={{ color: isEndTime || remainingTime5Minutes ? '#E80000' : 'unset' }}><Typography.Text>{timer.minutes}</Typography.Text></div>
      <div className="m-countdownTimer_separate" style={{ color: isEndTime || remainingTime5Minutes ? '#E80000' : 'unset' }}><Typography.Text>:</Typography.Text></div>
      <div className="m-countdownTimer_item" style={{ color: isEndTime || remainingTime5Minutes ? '#E80000' : 'unset' }}><Typography.Text>{timer.seconds}</Typography.Text></div>
    </div>
  );
};

export default React.memo(CountdownTimer);
