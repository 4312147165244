import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useDidMount from './useDidMount';
import useServiceWorker from './useServiceWorker';

import { getAccessToken, setLocalStorage } from 'services/common/storage';
import { getBillingAction, getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMenusAction } from 'store/menus';
import {
  getHeaderFootersAction,
  getPageErrorAsync,
  getStaticAllAction,
  getSystemAction,
} from 'store/system';
import chatZaloSDK from 'utils/chatZalo';
import { ERROR_CODE_SUPPORTED, LOCAL_STORAGE } from 'utils/constants';
import { getPathName } from 'utils/language';

const useInitializeRender = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateLocation = location.state as { buyAISection: boolean };
  const dispatch = useAppDispatch();
  const [isLoadingProfile, setLoadingProfile] = useState(false);
  const { pageError } = useAppSelector((state) => state.systems);
  const titleSystemSEO = useAppSelector((state) => state.systems.system?.seoData?.title);
  const idFanpageFB = useAppSelector((state) => state.systems.system?.others?.idFanpageFB);
  const fbId = useAppSelector((state) => state.systems.system?.others?.fbId);
  const idZalo = useAppSelector((state) => state.systems.system?.others?.idZalo);

  const token = getAccessToken();

  /*
    * https://gitlab.3forcom.com/3forcom/server-management/server-management-website-2023/-/blob/5f9563bfb1981b3a7fabed29adfac000efc91a08/src/components/molecules/CountdownTimer/index.tsx#L77
    * Update title for SEO When pathname change because above cause
    */
  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE.TITLE_CD, titleSystemSEO || 'Cloud Diffusion');
  }, [location.pathname, titleSystemSEO]);

  useEffect(() => {
    if (fbId || idFanpageFB) {
      const chatbox = document.getElementById('fb-customer-chat');
      if (chatbox && idFanpageFB) {
        chatbox.setAttribute('page_id', idFanpageFB); // "113717061743659"
        chatbox.setAttribute('attribution', 'biz_inbox');
      }

      if (fbId) {
        (window as any).fbAsyncInit = () => {
          (window as any).FB.init({
            appId: fbId,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v11.0'
          });
        };
      }
      (function (d, s, id) {
        const fjs: any = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        const js: any = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }, [idFanpageFB, fbId]);

  useEffect(() => {
    if (idZalo) {
      chatZaloSDK(idZalo);
    }
  }, [idZalo]);

  useDidMount(() => {
    dispatch(getHeaderFootersAction());
    dispatch(getMenusAction());
    dispatch(getStaticAllAction());
    if (token) {
      setLoadingProfile(true);
      dispatch(getProfileAction()).unwrap().finally(() => {
        setLoadingProfile(false);
      });
    }
    dispatch(getSystemAction()).unwrap()
      .then((res) => {
        if (res.others.favicon) {
          const favicon = document.getElementById('favicon') as HTMLLinkElement;
          favicon.href = res.others.favicon;
        }
      })
      .catch((errors: ErrorSystemType[]) => {
        const errCode = errors[0]?.code as GlobalErrorCode;
        if (errors && errors.length > 0 && ERROR_CODE_SUPPORTED.includes(errCode)) {
          if (!pageError) {
            dispatch(getPageErrorAsync());
          }
        }
      });
  });

  useEffect(() => {
    if (token) {
      dispatch(getBillingAction());
    }
  }, [dispatch, token]);

  useServiceWorker();

  useEffect(() => {
    if (!stateLocation?.buyAISection) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, stateLocation]);

  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data?.type === 'focus') {
        setLocalStorage(LOCAL_STORAGE.IS_FOCUS, 'true');
        navigate(getPathName('WORKSPACE'), { replace: true });
      }
    });
  }, [navigate]);

  return { isLoadingProfile };
};

export default useInitializeRender;
