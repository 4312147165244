import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { logoutService } from 'services/auth';
import { resetAuth } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsLogoutWithConfirmed } from 'store/system';

const useLogout = () => {
  const navigation = useNavigate();
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);
  const { isLogoutWithConfirmed } = useAppSelector((state) => state.systems);
  const dispatch = useAppDispatch();
  const logoutActions = () => {
    dispatch(resetAuth());
    if (isLogoutWithConfirmed) {
      dispatch(setIsLogoutWithConfirmed(false));
    } else {
      navigation('/');
    }
  };

  const onHandleLogout = async () => {
    try {
      setIsLoadingLogout(true);
      await logoutService();
      logoutActions();
    } catch (error: unknown) {
      if (error instanceof AxiosError && error?.response?.status === 401) {
        logoutActions();
      }
    } finally {
      setIsLoadingLogout(false);
    }
  };

  const onHandleBeforeLogout = () => {
    navigation('/');
    dispatch(setIsLogoutWithConfirmed(true));
  };

  return {
    onHandleLogout,
    onHandleBeforeLogout,
    isLoadingLogout
  };
};

export default useLogout;
