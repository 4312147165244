import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getBillingService, getProfileService } from 'services/auth';
import {
  BillingDataType,
  ProcessingType,
  ProfileDataType
} from 'services/auth/types';
import { removeAccessToken, removeLocalStorage, removeRefreshAccessToken } from 'services/common/storage';
import { LOCAL_STORAGE, StatusConnectType } from 'utils/constants';
import { SocketStatus } from 'utils/socket';

export interface AIServerResponseData {
  type: string | null;
  data: {
    serverLink?: string;
    status: ProcessingType;
    duration: number;
    queue: number;
  }
}

export interface AIServerResultData {
  type: string | null;
  serverLink?: string;
  status: ProcessingType;
  duration: number;
  queue: number;
}
interface AuthState {
  profile?: ProfileDataType;
  infoBillingLoading?: boolean;
  infoBilling?: BillingDataType;
  isLoading: boolean;
  isLogout: boolean;
  aiServerResult: AIServerResultData;
  isLoadingProcessService: boolean;
  statusSocket: string;
}

const initialState: AuthState = {
  profile: undefined,
  infoBillingLoading: false,
  infoBilling: undefined,
  isLoading: false,
  isLoadingProcessService: false,
  isLogout: false,
  aiServerResult: {
    serverLink: '',
    status: 'stopped',
    type: '',
    duration: 0,
    queue: 0,
  },
  statusSocket: SocketStatus.DISCONNECTED
};

export const getProfileAction = createAsyncThunk<ProfileDataType>(
  'profileReducer/getProfileService',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getProfileService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBillingAction = createAsyncThunk<BillingDataType>(
  'billingReducer/getBillingService',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getBillingService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    resetAuth($state) {
      $state.profile = undefined;
      $state.infoBilling = undefined;
      $state.isLogout = true;
      removeAccessToken();
      removeRefreshAccessToken();
      removeLocalStorage(LOCAL_STORAGE.PAYMENT);
      removeLocalStorage(LOCAL_STORAGE.INFO_PAYMENT_SESSION);
      removeLocalStorage(LOCAL_STORAGE.USAGE_TIME_SESSION);
      removeLocalStorage(LOCAL_STORAGE.USAGE_TIME_SESSION);
    },
    updateProfile($state, action: PayloadAction<ProfileDataType>) {
      $state.profile = action.payload;
      $state.aiServerResult = {
        serverLink: action.payload.customerData.serverLink || '',
        status: action.payload.customerData.usingServer,
        duration: action.payload.customerData.duration,
        queue: action.payload.customerData.queue,
        type: ''
      };
    },
    updateBilling($state, action: PayloadAction<BillingDataType>) {
      $state.infoBilling = action.payload;
    },
    updateProcessService($state, action: PayloadAction<AIServerResultData>) {
      $state.aiServerResult = action.payload;
    },
    setLoadingProcessService($state, action: PayloadAction<boolean>) {
      $state.isLoadingProcessService = action.payload;
    },
    setStatusSocket($state, action: PayloadAction<StatusConnectType>) {
      $state.statusSocket = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfileAction.pending, ($state) => {
      $state.isLoading = true;
    });
    builder.addCase(getProfileAction.fulfilled, ($state, action) => {
      $state.profile = action.payload;
      $state.aiServerResult = {
        serverLink: action.payload.customerData.serverLink || '',
        status: action.payload.customerData.usingServer,
        duration: action.payload.customerData.usingServer === 'starting' ? action.payload.customerData.duration - action.payload.customerData.tempTime : action.payload.customerData.duration,
        queue: action.payload.customerData.queue,
        type: ''
      };
      $state.isLoading = false;
    });
    builder.addCase(getProfileAction.rejected, ($state) => {
      $state.isLoading = false;
    });
    builder.addCase(getBillingAction.pending, ($state) => {
      $state.infoBillingLoading = true;
    });
    builder.addCase(getBillingAction.fulfilled, ($state, action) => {
      $state.infoBilling = action.payload;
      $state.infoBillingLoading = false;
    });
    builder.addCase(getBillingAction.rejected, ($state) => {
      $state.infoBillingLoading = false;
    });
  },
});

export const {
  resetAuth,
  updateProfile,
  updateBilling,
  updateProcessService,
  setLoadingProcessService,
  setStatusSocket,
} = authSlice.actions;

export default authSlice.reducer;
