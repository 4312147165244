import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  getAccessToken, removeAccessToken,
  removeRefreshAccessToken, setLocalStorage
} from './storage';

import { LOCAL_STORAGE } from 'utils/constants';
import { getPathName } from 'utils/language';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  ($config: AxiosRequestConfig): AxiosRequestConfig => {
    if ($config.headers) {
      const token = getAccessToken();
      if (token) {
        $config.headers.Authorization = `Bearer ${token}`;
      }
      if ($config.method === 'get') {
        $config.params = {
          ...$config.params,
          locale: window.localStorage.getItem(LOCAL_STORAGE.LANGUAGE) || 'en',
        };
      }
      $config.headers['Content-Type'] = 'application/json';
      $config.headers.Accept = 'application/json';
    }
    return $config;
  },
  async (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  async (error: AxiosError): Promise<AxiosError> => {
    if (error.response?.status === 401) {
      setLocalStorage(LOCAL_STORAGE.PATH_NAME, window.location.pathname);
      removeAccessToken();
      removeRefreshAccessToken();
      if (!window.location.pathname.includes(getPathName('AUTH'))) {
        window.location.href = getPathName('AUTH');
      }
    }
    return Promise.reject(
      error.response && error.response.status === 422 ? (error.response.data as any).errors : error,
    );
  },
);

export default axiosInstance;
