import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'tfc-components';

import Notify, { NotifyData } from 'components/organisms/Notify';
import AuthLayout from 'components/templates/AuthLayout';
import LoginForm from 'components/templates/LoginForm';
import HelmetContainer from 'components/templates/Mainlayout/helmet';
import SignupForm from 'components/templates/SignupForm';
import { getAccessToken } from 'services/common/storage';
import { CONSTANT_TAB_AUTH } from 'utils/constants';
import { getPathName } from 'utils/language';

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateAuth = location.state as { auth?: string };
  const [activeTab, setActiveTab] = useState(stateAuth?.auth || CONSTANT_TAB_AUTH.LOGIN);
  const [notify, setNotify] = useState<NotifyData>({
    isOpen: false,
    isSuccess: true,
    message: ''
  });

  useEffect(() => {
    window.addEventListener('popstate', () => {
      navigate('/');
    });
    return () => window.removeEventListener('popstate', () => navigate('/'));
  }, [navigate]);

  useEffect(() => {
    const token = getAccessToken();
    if (token) navigate('/');
  }, [navigate]);

  return (
    <div className="p-register">
      <HelmetContainer />
      <AuthLayout>
        <Tabs
          value={activeTab}
        >
          <Tabs.List>
            <Tabs.Item
              extendClasses="p-register_tabsItem color-raisinBlack fs-18"
              value={CONSTANT_TAB_AUTH.SIGNUP}
              activeClass="p-register_tabsActive"
              handleChangeTab={() => setActiveTab(CONSTANT_TAB_AUTH.SIGNUP)}
            >
              Sign Up
            </Tabs.Item>
            <Tabs.Item
              extendClasses="p-register_tabsItem color-raisinBlack fs-18"
              value={CONSTANT_TAB_AUTH.LOGIN}
              activeClass="p-register_tabsActive"
              handleChangeTab={() => setActiveTab(CONSTANT_TAB_AUTH.LOGIN)}
            >
              Log In
            </Tabs.Item>
          </Tabs.List>
          <Tabs.Content value={CONSTANT_TAB_AUTH.LOGIN}>
            <LoginForm
              forgotPasswordHref={getPathName('FORGOT_PASSWORD')}
              handleSignUp={() => setActiveTab(CONSTANT_TAB_AUTH.SIGNUP)}
            />
          </Tabs.Content>
          <Tabs.Content value={CONSTANT_TAB_AUTH.SIGNUP}>
            <SignupForm
              handleSignIn={(isLoginForm) => {
                if (isLoginForm) return setActiveTab(CONSTANT_TAB_AUTH.LOGIN);
                return setNotify({
                  isOpen: true,
                  message: '<p>Congratulations!</p><p>Your account has been successfully created</p>',
                  isSuccess: true
                });
              }}
            />
          </Tabs.Content>
        </Tabs>
        <Tabs />
      </AuthLayout>
      <Notify
        {...notify}
        onHandleAction={() => {
          setNotify({
            isOpen: false,
            message: '',
            isSuccess: true
          });
          setActiveTab(CONSTANT_TAB_AUTH.LOGIN);
        }}
      />
    </div>
  );
};

export default Auth;
