import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useBlocker, { AutoUnBlockingTypes } from './useBlocker';
import useLogout from './useLogout';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setIsLogoutWithConfirmed } from 'store/system';

function useCallbackPrompt(when: boolean): {
  showPrompt: boolean,
  confirmNavigation(): void,
  cancelNavigation(): void
} {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLogoutWithConfirmed } = useAppSelector((state) => state.systems);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<AutoUnBlockingTypes | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);

  const { onHandleLogout } = useLogout();

  // Reference: https://dev.to/bangash1996/detecting-user-leaving-page-with-react-router-dom-v602-33ni

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    dispatch(setIsLogoutWithConfirmed(false));
  }, [dispatch]);

  console.log('when: ', when);

  const handleBlockedNavigation = useCallback(
    (nextLocation: AutoUnBlockingTypes) => {
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (isLogoutWithConfirmed) onHandleLogout();
      navigate(lastLocation.location.pathname);

      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, isLogoutWithConfirmed, lastLocation, navigate, onHandleLogout]);

  useBlocker(handleBlockedNavigation, when);

  return { showPrompt, confirmNavigation, cancelNavigation };
}

export default useCallbackPrompt;
