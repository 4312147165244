import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';

import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import Mainlayout from 'components/templates/Mainlayout';
import useInitializeRender from 'hooks/useInitializeRender';
import ProtectedRoute from 'navigations/ProtectedRoute';
import Auth from 'pages/Auth';
import ForgotPassword from 'pages/ForgotPassword';
import Social from 'pages/Social';
import useRoutesList from 'routes';
import { store } from 'store';
import { CONSTANT_ROUTES_EN } from 'utils/constants';

const App: React.FC = () => {
  const { isLoadingProfile } = useInitializeRender();
  const { routes } = useRoutesList();

  if (!routes || isLoadingProfile) return <LoadingFullScreen width={50} />;

  return (
    <Suspense fallback={<LoadingFullScreen width={50} />}>
      <Routes>
        <Route path={CONSTANT_ROUTES_EN.SOCIAL} element={<Social />} />
        <Route path={CONSTANT_ROUTES_EN.AUTH} element={<Auth />} />
        <Route path={CONSTANT_ROUTES_EN.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route
          element={(
            <Mainlayout />
          )}
        >
          {(Object.keys(routes) as Array<keyof typeof routes>).map(
            (ele) => routes[ele].path.map((item, index) => {
              if (routes[ele].isProtected) {
                return (
                  <Route
                    key={`router-${ele}-${index.toString()}`}
                    path={item}
                    element={(
                      <ProtectedRoute path={item}>
                        {routes[ele].element}
                      </ProtectedRoute>
                    )}
                  />
                );
              }
              return (
                <Route
                  key={`router-${ele}-${index.toString()}`}
                  path={item}
                  element={routes[ele].element}
                />
              );
            }),
          )}
        </Route>
      </Routes>
    </Suspense>
  );
};

const AppProvider: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppProvider;
