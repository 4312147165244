import React, { useMemo } from 'react';
import { Loading as LoadingTFC } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface LoadingProps {
  width?: number;
  variant?: 'fullscreen' | 'default';
}

const Loading: React.FC<LoadingProps> = ({ width, variant = 'default' }) => {
  const widthVariant = useMemo(() => {
    if (!width && variant === 'default') return 30;
    if (!width && variant === 'fullscreen') return 50;
    return width;
  }, [width, variant]);

  return (
    <div className={mapModifiers('a-loading', variant)}>
      <div style={{ marginLeft: `-${(widthVariant || 30) / 2}px` }}>
        <LoadingTFC.CircleDashed width={width} />
      </div>
    </div>
  );
};

export default Loading;
