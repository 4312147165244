import { yupResolver } from '@hookform/resolvers/yup';
import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Controller, FormProvider,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Image,
  Input,
  Label,
  Link,
  Typography,
} from 'tfc-components';

import imageNotification from 'assets/images/imageNotification.png';
import Loading from 'components/atoms/Loading';
import Pulldown from 'components/molecules/Pulldown';
import LoginSocials from 'components/organisms/LoginSocials';
import CustomModal from 'components/organisms/Modal';
import useAddress from 'hooks/useAddress';
import { postCustomerRegisterService } from 'services/auth';
import { getLocalStorage, setAccessToken, setRefreshToken } from 'services/common/storage';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE } from 'utils/constants';
import mapModifiers from 'utils/functions';
import { getPathName } from 'utils/language';
import useSchemas from 'utils/schemas';

export type SignUpFormReturnTypes = {
  email: string;
  phone?: string;
  password: string;
  password_confirmation: string;
  address?: string;
  countryCode: OptionType | null;
  provinceCode: OptionType | null;
  agree: boolean;
};

interface SignUpFormProps {
  handleSignIn: (isLoginForm?: boolean) => void;
}

const SignupForm: React.FC<SignUpFormProps> = ({
  handleSignIn,
}) => {
  const { t } = useTranslation();
  const { state: stateLocation } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const statePath = stateLocation as { path?: string };
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { system, staticAll } = useAppSelector((state) => state.systems);
  const {
    data: privacyPolicyData,
    isLoading: isLoadingPV
  } = useAppSelector((state) => state.privacyPolicy);
  const [isOpen, setIsOpen] = useState(false);
  const [hasErrorCapcha, setHasErrorCapcha] = useState(false);
  const [isOpenPrivacyPolicy, setIsOpenPrivacyPolicy] = useState<{
    isOpen: boolean;
    type?: 'PRIVACY_POLICY' | 'TERMSOFUSE'
  }>({
    isOpen: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const slugPrivacyPolicy = staticAll?.find((item) => item.pageData.code === 'PRIVACY_POLICY_PAGE')
    ?.pageData.slug;

  const slugTerms = staticAll?.find((item) => item.pageData.code === 'TERMS_OF_USE_PAGE')
    ?.pageData.slug;

  const { signUp } = useSchemas();

  const method = useForm<SignUpFormReturnTypes>({
    mode: 'onChange',
    resolver: yupResolver<SignUpFormReturnTypes>(signUp),
    defaultValues: {
      agree: false,
      address: '',
      password_confirmation: '',
      countryCode: undefined,
      email: '',
      password: '',
      phone: '',
      provinceCode: undefined,
    }
  });

  const detectError = (code: string) => {
    switch (code) {
      case 'unique': return t('signUp_form.already_exists');
      case 'phone': return t('signUp_form.invalid');
      default: return '';
    }
  };

  const { mutate } = useMutation(postCustomerRegisterService, {
    onSuccess: (data) => {
      method.reset();
      const { accessToken, refreshToken } = data;
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      dispatch(getProfileAction()).unwrap().then((res) => {
        const usageTimeSession = getLocalStorage(LOCAL_STORAGE.USAGE_TIME_SESSION);
        const isFocusGoToWorkSpace = getLocalStorage(LOCAL_STORAGE.IS_FOCUS);
        if (isFocusGoToWorkSpace) {
          navigate(`${getPathName('WORKSPACE')}?isFocus=true`, { replace: true });
        } else if (usageTimeSession) {
          navigate(getPathName('PAYMENT'), { replace: true });
        } else if (res.customerData && res.customerData.duration > 0) {
          navigate(getPathName('WORKSPACE'), { replace: true });
        } else {
          navigate(statePath?.path || '/', { replace: true });
        }
      });
    },
    onError: (error) => {
      if (error && Array.isArray(error)) {
        error.forEach((item: ErrorAPIResponseTypes) => {
          if (item.field as keyof SignUpFormReturnTypes) {
            const filedData = `${item.field?.charAt(0)?.toUpperCase()}${item.field?.slice(1)}`;
            method.setError(
              item.field as keyof SignUpFormReturnTypes,
              { message: `${filedData} ${detectError(item.code)}` }
            );
          }
        });
      } else {
        setIsOpen(!isOpen);
      }
    },
    onSettled: () => {
      if (recaptchaRef?.current) {
        recaptchaRef.current.reset();
      }
    }
  });

  const onHandleSubmitForm = (data: SignUpFormReturnTypes) => {
    try {
      if (!recaptchaRef.current) return;
      setIsLoading(true);
      const token = recaptchaRef.current.getValue();
      if (!token) {
        setHasErrorCapcha(true);
        setIsLoading(false);
        return;
      }
      mutate({
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        ggRecaptchaToken: token,
        ...(data.phone && { phone: data.phone }),
        ...(data.address && { address: data.address }),
        ...(data.provinceCode && { provinceCode: data.provinceCode?.value?.toString() }),
        ...(data.countryCode && { countryCode: data.countryCode?.value?.toString() }),
      });
    } catch {
      // Empty
    } finally {
      setIsLoading(false);
    }
  };

  const codeCountry = method.watch('countryCode');

  const { countries, provinces } = useAddress({
    codeCountry: codeCountry?.id?.toString(),
  });

  return (
    <div className="t-signUpForm">
      <FormProvider
        {...method}
      >
        <form
          onSubmit={method.handleSubmit(onHandleSubmitForm)}
        >
          <div className="t-signUpForm_field">
            <Label extendClasses="fs-14" spacingBottom={0} required>{t('system.email')}</Label>
            <Controller
              name="email"
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    {...field}
                    extendClasses={mapModifiers('t-signUpForm_input', error && 'error')}
                    status={error ? 'error' : undefined}
                  />
                  {error && (
                    <div className="t-signUpForm_error">{error.message}</div>
                  )}
                </>
              )}
            />
          </div>
          <div className="t-signUpForm_field">
            <Label extendClasses="fs-14" spacingBottom={0}>{t('system.phone')}</Label>
            <Controller
              name="phone"
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    {...field}
                    type="tel"
                    extendClasses={mapModifiers('t-signUpForm_input', error && 'error')}
                    status={error ? 'error' : undefined}
                  />
                  {error && (
                    <div className="t-signUpForm_error">{error.message}</div>
                  )}
                </>
              )}
            />
          </div>
          <Row>
            <Col lg="6" className="t-signUpForm_field">
              <Label extendClasses="fs-14" spacingBottom={0} required>{t('system.password')}</Label>
              <Controller
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input
                      {...field}
                      type="password"
                      extendClasses={mapModifiers('t-signUpForm_input', error && 'error')}
                      status={error ? 'error' : undefined}
                    />
                    {error && (
                      <div className="t-signUpForm_error">{error.message}</div>
                    )}
                  </>
                )}
              />
            </Col>
            <Col lg="6" className="t-signUpForm_field">
              <Label extendClasses="fs-14" spacingBottom={0} required>{t('system.confirmPassword')}</Label>
              <Controller
                name="password_confirmation"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input
                      {...field}
                      type="password"
                      extendClasses={mapModifiers('t-signUpForm_input', error && 'error')}
                      status={error ? 'error' : undefined}
                    />
                    {error && (
                      <div className="t-signUpForm_error">{error.message}</div>
                    )}
                  </>
                )}
              />
            </Col>
          </Row>
          <div className="t-signUpForm_field">
            <Label extendClasses="fs-14" spacingBottom={0}>{t('system.address')}</Label>
            <Controller
              name="address"
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    {...field}
                    extendClasses={mapModifiers('t-signUpForm_input', error && 'error')}
                    status={error ? 'error' : undefined}
                  />
                  {error && (
                    <div className="t-signUpForm_error">{error.message}</div>
                  )}
                </>
              )}
            />
          </div>
          <Row>
            <Col lg="6" className="t-signUpForm_field">
              <Label extendClasses="fs-14" spacingBottom={0}>{t('system.country')}</Label>
              <Controller
                name="countryCode"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Pulldown
                      {...field}
                      className="t-signUpForm_pulldown"
                      optionsData={countries.options || []}
                      error={error?.message}
                      handleChange={field.onChange}
                    />
                    {error && (
                      <div className="t-signUpForm_error">{error.message}</div>
                    )}
                  </>
                )}
              />
            </Col>
            <Col lg="6" className="t-signUpForm_field">
              <Label extendClasses="fs-14" spacingBottom={0}>{t('system.stateProvince')}</Label>
              <Controller
                name="provinceCode"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Pulldown
                      {...field}
                      className="t-signUpForm_pulldown"
                      optionsData={provinces.options || []}
                      error={error?.message}
                      handleChange={field.onChange}
                    />
                    {error && (
                      <div className="t-signUpForm_error">{error.message}</div>
                    )}
                  </>
                )}
              />
            </Col>
          </Row>
          <div className="t-signUpForm_agree">
            <Controller
              name="agree"
              render={({ field, fieldState: { error } }) => (
                <>
                  <Checkbox
                    {...field}
                    size={18}
                    activeColor="#0C5DA5"
                    value={field.value}
                    checked={field.value}
                    {...(field.value && { extendClasses: 't-signUpForm_agree-checked' })}
                  >
                    <p className="t-signUpForm_agree_text">
                      {t('system.agree')}
                      {' '}
                      <Link target="_blank" useExternal href={`/${slugPrivacyPolicy}`}>{t('system.policy')}</Link>
                      {' '}
                      {t('system.and')}
                      {' '}
                      <Link target="_blank" href={`/${slugTerms}`}>{t('system.terms')}</Link>
                      .
                    </p>
                  </Checkbox>
                  {error && (
                    <div className="t-signUpForm_error">{error.message}</div>
                  )}
                </>
              )}
            />
          </div>
          {
            system?.googleRecaptchaKey && (
              <div className="t-signUpForm_recaptcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={system?.googleRecaptchaKey}
                  size="normal"
                  onChange={(token) => {
                    if (token) {
                      setHasErrorCapcha(false);
                    }
                  }}
                />
              </div>
            )
          }
          {hasErrorCapcha && (
            <div className="t-signUpForm_error" style={{ textAlign: 'center' }}>{`${t('notify.not_a_robot')}`}</div>
          )}
          <div className="t-signUpForm_submit">
            <Button
              variant="primary"
              primaryColor="#0C5DA5"
              hoveredPrimaryColor="#106EC1"
              type="submit"
              loading={isLoading}
              loadingIndicator={<Loading variant="default" />}
              disabled={isLoading}
            >
              <Typography.Text textStyle="uppercase" fontweight="600">
                {t('system.createAccount')}
              </Typography.Text>
            </Button>
          </div>
          <div className="t-signUpForm_signUp">
            <Typography.Text extendClasses="fs-14">{t('system.alreadyHaveAccount')}</Typography.Text>
            <Link onClick={() => handleSignIn(true)}>
              <Typography.Text extendClasses="fs-14 color-mediumPersianBlue">{t('system.logIn')}</Typography.Text>
            </Link>
          </div>
        </form>
        <LoginSocials />
      </FormProvider>
      <CustomModal
        variant="maxWith464"
        isOpen={isOpen}
        handleClose={() => setIsOpen(!isOpen)}
      >
        <Image
          ratio={252 / 24}
          imgSrc={imageNotification}
          alt="Payment"
          extendClasses="t-signUpForm_modalImage"
        />
        <div className="t-signUpForm_content">
          <Typography.Text
            textStyle="center"
            extendClasses="color-cadmiumRed"
          >
            Failed to sign up. Please try again later.
          </Typography.Text>
        </div>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          extendClasses="t-signUpForm_modalButton"
        >
          <Typography.Text
            textStyle="underline"
            fontweight="500"
            extendClasses="color-raisinBlack"
          >
            Try again
          </Typography.Text>
        </Button>
      </CustomModal>
      <CustomModal
        variant="maxWith1100"
        showIconClose
        isOpen={isOpenPrivacyPolicy.isOpen}
        handleClose={() => setIsOpenPrivacyPolicy({ ...isOpenPrivacyPolicy, isOpen: false })}
      >
        <div className="t-signUpForm_privacyPolicy">
          <div className="t-signUpForm_privacyPolicy_content">
            {
              isLoadingPV && (
                <div className="t-signUpForm_privacyPolicy_loading">
                  <Loading width={50} variant="default" />
                </div>
              )
            }
            <div
              className="fs-14"
              dangerouslySetInnerHTML={{
                __html:
                  isOpenPrivacyPolicy.type === 'PRIVACY_POLICY'
                    ? (privacyPolicyData?.blocks?.privacyPolicy?.content.data || '')
                    : (privacyPolicyData?.blocks?.termsOfUse?.content.data || '')
              }}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SignupForm;
