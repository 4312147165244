import React from 'react';
import { Loading as LoadingTFC } from 'tfc-components';

interface LoadingProps {
  width?: number;
}

const LoadingFullScreen: React.FC<LoadingProps> = ({ width }) => (
  <div className="a-loadingFullScreen">
    <div style={{ marginLeft: `-${width || 30 / 2}px` }}>
      <LoadingTFC.CircleDashed width={width} />
    </div>
  </div>
);

export default LoadingFullScreen;
