import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from 'tfc-components';

import Notify, { NotifyData } from '../Notify';

import Icon from 'components/atoms/Icon';
import Loading from 'components/atoms/Loading';
import { getPKCESocialDataService, loginSocialService } from 'services/auth';
import { LoginSocialParamsTypes } from 'services/auth/types';
import {
  getLocalStorage,
  setAccessToken, setLocalStorage, setRefreshToken
} from 'services/common/storage';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CONSTANT_ROUTES, LOCAL_STORAGE, SOCIAL_LOGIN_HOST } from 'utils/constants';
import { getPathName } from 'utils/language';

const LoginSocials: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state: stateLocation } = useLocation();
  const statePath = stateLocation as { path?: string };

  const loginFacebookScopes = useAppSelector((stateApp) => stateApp.systems
    .system?.others.loginFacebookScopes);

  const [notify, setNotify] = useState<NotifyData>({
    isOpen: false,
    isSuccess: true,
    message: ''
  });

  const { mutate: getPCKESocial, isLoading: isLoadingPCKESocial } = useMutation(
    'getPCKESocialMutate',
    getPKCESocialDataService,
    {
      onSuccess(data, variables) {
        const pkceState = Math.random().toString(36).slice(2);
        setLocalStorage(LOCAL_STORAGE.SOCIAL_PKCE_UUID, data?.uuid);
        setLocalStorage(LOCAL_STORAGE.SOCIAL_PKCE_STATE, pkceState);
        const params = {
          response_type: data.responseType,
          client_id: data.clientId,
          code_challenge: data.codeChallenge,
          code_challenge_method: data.codeChallengeMethod,
          state: pkceState,
        };

        const googleParams = {
          ...params,
          redirect_uri: `${window.location.origin}${CONSTANT_ROUTES.LOGIN_GOOGLE}`,
          scope: `${data.scopes.join(' ')}`,
          prompt: 'select_account',
          include_granted_scopes: 'true',
        };
        switch (variables) {
          case 'google':
            window.location.replace(`${SOCIAL_LOGIN_HOST.GOOGLE}?${new URLSearchParams(googleParams).toString()}`);
            break;
          default:
            break;
        }
      },
      onError(error) {
        console.log('error', error);
      }
    }
  );

  const { mutate: handleLoginSocial, isLoading } = useMutation(
    ['loginSocialMutate'],
    (data: LoginSocialParamsTypes) => loginSocialService(data),
    {
      onSuccess(data) {
        const { accessToken, refreshToken } = data;

        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        dispatch(getProfileAction()).unwrap().then((res) => {
          const usageTimeSession = getLocalStorage(LOCAL_STORAGE.USAGE_TIME_SESSION);
          const isFocusGoToWorkSpace = getLocalStorage(LOCAL_STORAGE.IS_FOCUS);
          if (isFocusGoToWorkSpace) {
            navigate(`${getPathName('WORKSPACE')}?isFocus=true`, { replace: true });
          } else if (usageTimeSession) {
            navigate(getPathName('PAYMENT'), { replace: true });
          } else if (res.customerData && res.customerData.duration > 0) {
            navigate(getPathName('WORKSPACE'), { replace: true });
          } else {
            navigate(statePath?.path || '/', { replace: true });
          }
        });
      },
      onError(error) {
        const errors = error as Array<ErrorAPIResponseTypes>;
        const message: string[] = [];

        if (errors && errors.length > 0) {
          errors.forEach((item) => {
            if (item?.code === 'unique') {
              message.push(t('notify.email_not_exists'));
            } else {
              message.push(item?.message || item?.title);
            }
          });
        }

        setNotify({
          isOpen: true,
          message: message.join(', '),
          isSuccess: false
        });
      }
    }
  );

  const loginWithGoogle = async () => {
    getPCKESocial('google');
  };

  const onHandleLoginFacebook = async () => {
    try {
      const joinScopes = loginFacebookScopes?.join(',');
      (window as any)?.FB?.login((response: any) => {
        if (response && response.authResponse && response.authResponse.accessToken) {
          handleLoginSocial({
            accessToken: response.authResponse.accessToken,
            provider: 'facebook',
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, (loginFacebookScopes && loginFacebookScopes?.length > 0)
        ? { scope: joinScopes } : {});
    } catch (e) {
      console.log('FB Login: ', e);
    }
  };

  const onHandleContinue = () => {
    setNotify({
      isOpen: false,
      isSuccess: true,
      message: '',
    });
  };

  return (
    <div className="o-loginSocials">
      {(isLoading || isLoadingPCKESocial) && <Loading variant="fullscreen" />}
      <div className="o-loginSocials_others">
        <Typography.Text extendClasses="o-loginSocials_others-text" textStyle="center" fontweight="500">
          {t('system.orLogin')}
        </Typography.Text>
      </div>
      <div className="o-loginSocials_google">
        <button type="button" onClick={onHandleLoginFacebook} disabled={isLoading}>
          <Icon iconName="facebook" size="24" />
          <Typography.Text extendClasses="o-loginSocials_google-text">
            {t('system.continueFacebook')}
          </Typography.Text>
        </button>
      </div>
      <div className="o-loginSocials_google">
        <button type="button" onClick={loginWithGoogle} disabled={isLoadingPCKESocial}>
          <Icon iconName="google" size="24" />
          <Typography.Text extendClasses="o-loginSocials_google-text">
            {t('system.continueGoogle')}
          </Typography.Text>
        </button>
      </div>
      <Notify
        {...notify}
        onHandleAction={onHandleContinue}
      />
    </div>
  );
};

export default React.memo(LoginSocials);
