import React from 'react';
import { Button, Typography } from 'tfc-components';

import CustomModal from '../Modal';

import { useAppSelector } from 'store/hooks';

interface ModalConfirmLeaveProps {
  isShowPrompt: boolean;
  confirmNavigation(): void;
  cancelNavigation(): void;
  isConfirmStop?: boolean;
}

const ModalConfirmLeave: React.FC<ModalConfirmLeaveProps> = ({
  isShowPrompt, cancelNavigation, confirmNavigation,
  isConfirmStop
}) => {
  const { header } = useAppSelector((state) => state.systems);

  return (
    <CustomModal
      variant="maxWith464"
      isOpen={isShowPrompt}
    >
      <div className="t-playground_modal_wrapBody">
        <div className="t-playground_modal_logo">
          <img alt="CD" src={header?.blocks?.section?.logoSrc?.data?.path || ''} />
        </div>
        <div className="t-playground_modal_content">
          <Typography.Text textStyle="center">
            {isConfirmStop ? `Are you sure you want to stop?
                You might lose any changes you’ve made on this page.`
              : `Are you sure you want to leave?
                You might lose any changes you’ve made on this page.`}
          </Typography.Text>
        </div>
        <div className="t-playground_modal_buttons">
          <Button onClick={confirmNavigation}>
            <Typography.Text style={{ textDecoration: 'underline' }}>
              {
                isConfirmStop ? 'Stop' : 'Leave'
              }
            </Typography.Text>
          </Button>
          <Button onClick={cancelNavigation}>
            <Typography.Text style={{ textDecoration: 'underline' }}>Stay</Typography.Text>
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalConfirmLeave;
