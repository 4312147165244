import { CONSTANT_ROUTES, CONSTANT_ROUTES_EN, ConstantRoutesCodes } from './constants';

import i18n from 'i18n';
import { LanguageKey, LanguageKeys, LocalesType } from 'services/pages/types';

function getActiveLanguages(locales?: LocalesType) {
  if (locales) {
    return (Object.keys(locales) as Array<LanguageKey>).reduce(
      (prev: Array<LanguageKey>, curr: LanguageKey) => (locales[curr].active
        ? [...prev, curr] : [...prev]),
      [],
    );
  }
  return [];
}

const checkActiveLang = (
  activeLang: LanguageKey,
  listActive?: LocalesType,
): boolean => {
  if (listActive && activeLang) {
    return !!listActive[activeLang].active;
  }
  return false;
};

function getHomeLangURL(lang?: string) {
  if (lang && lang !== 'vi') return `/${lang}`;
  return '/';
}

function getLangURL(lang?: string) {
  if (lang && lang !== 'vi') return `/${lang}/`;
  return '/';
}

const langLabel = (key: string) => {
  switch (key) {
    case 'vi':
      return 'VI';
    case 'en':
      return 'EN';
    default:
      return '';
  }
};

export function getPathName(code: ConstantRoutesCodes, lang?: string) {
  switch (lang || i18n.language) {
    case 'en':
      return `${CONSTANT_ROUTES_EN[code]}`;
    default:
      return `${CONSTANT_ROUTES[code]}`;
  }
}

const getStaticSlugWithUrl = (code: ConstantRoutesCodes, slug?: string) => {
  if (!slug) return '';
  switch (i18n.language) {
    case 'en':
      return `en/${CONSTANT_ROUTES_EN[code]}/${slug}`;
    default:
      return `${CONSTANT_ROUTES[code]}/${slug}`;
  }
};

const getHomeLangURLWithI18 = () => {
  if (i18n.language !== 'vi') return `/${i18n.language}`;
  return '/';
};

function getLangWithUrl(slug?: string) {
  if (i18n.language && i18n.language !== 'vi') return `/${i18n.language}/${slug}`;
  return `/${slug}`;
}

function convertRoute(langList: LanguageKeys, slug?: string) {
  return langList.map((ele) => `${getLangURL(ele)}${slug || ''}`);
}

function convertHomeRoute(langList: LanguageKeys) {
  return langList.map((ele) => getHomeLangURL(ele));
}

function convertStaticRoute(
  code: ConstantRoutesCodes,
  langList: LanguageKeys,
  noSlug?: boolean,
) {
  return langList.map(
    (ele) => `${getLangURL(ele)}${getPathName(code, ele)}${noSlug ? '' : '/:slug'}`,
  );
}

export function getLangPageTranslation(lang?: string, isHome?: boolean) {
  if (lang && lang !== 'vi' && !isHome) return lang;
  if (lang && lang !== 'vi') return `${lang}/`;
  return '';
}

export default {
  getActiveLanguages,
  checkActiveLang,
  getHomeLangURL,
  getLangURL,
  langLabel,
  getPathName,
  getStaticSlugWithUrl,
  getHomeLangURLWithI18,
  getLangWithUrl,
  convertRoute,
  convertHomeRoute,
  convertStaticRoute,
  getLangPageTranslation,
};
