import React, { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

import Notify, { NotifyData } from 'components/organisms/Notify';
import { sendOtpService, updatePasswordOtpService, verifyOtpService } from 'services/auth';
import { useAppSelector } from 'store/hooks';
import { checkTypeErrors, getErrors } from 'utils/functions';
import { getPathName } from 'utils/language';

export type ForgotPasswordFormTypes = {
  verificationCode: string,
  email: string,
  newPassword: string,
  newPasswordConfirm: string,
};

interface ForgotPasswordProps { }

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [mailState, setMailState] = useState('');
  const [notify, setNotify] = useState<NotifyData>({
    isOpen: false,
    isSuccess: true,
    message: ''
  });
  const [notifyRequestOTP, setNotifyRequestOTP] = useState<NotifyData>({
    isOpen: false,
    isSuccess: true,
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const method = useForm<ForgotPasswordFormTypes>({
    mode: 'onChange',
  });
  const googleRecaptchaKey = useAppSelector((state) => state.systems.system?.googleRecaptchaKey);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const handleStepOne = async (email: string) => {
    try {
      setMailState(email);
      if (!recaptchaRef.current) return;
      const token = await recaptchaRef.current.executeAsync();
      if (!token) return;
      setIsLoading(true);
      await sendOtpService({
        email,
        ggRecaptchaToken: token
      });
      setNotifyRequestOTP({
        isOpen: true,
        isSuccess: true,
        message: t('notify.check_otp_your_mail')
      });
      setStep(2);
    } catch (error) {
      const errors = checkTypeErrors(error);

      if (errors && errors.length > 0) {
        const message: string[] = [];
        errors.forEach((item) => {
          if (item?.code == '404') {
            message.push(t('notify.email_not_exists'));
          } else {
            message.push(t('notify.email_incorrect'));
          }
        });
        method.setError('email', {
          message: message.join(', ')
        });
      } else {
        setNotify({
          isOpen: true,
          isSuccess: false,
          message: 'Failed. Please try again later.'
        });
      }
    } finally {
      recaptchaRef.current?.reset();
      setIsLoading(false);
    }
  };

  const handleStepTwo = async (code: string, email: string) => {
    try {
      if (!recaptchaRef.current) return;
      const token = await recaptchaRef.current.executeAsync();
      if (!token) return;
      setIsLoading(true);
      await verifyOtpService({
        email,
        ggRecaptchaToken: token,
        code
      });
      setStep(3);
    } catch (error) {
      const errors = error as Array<ErrorAPIResponseTypes>;

      if (errors && errors.length > 0) {
        const message: string[] = [];
        errors.forEach((item) => {
          if (item?.code === 'otpInvalid') {
            message.push(t('notify.otp_code_incorrect'));
          } else if (item?.code === 'otpIsExpired') {
            message.push(t('notify.otpIsExpired'));
          } else {
            message.push(item?.message || item?.title);
          }
        });
        method.setError('verificationCode', {
          message: message.join(', ')
        });
      } else {
        setNotify({
          isOpen: true,
          isSuccess: false,
          message: 'Failed. Please try again later.'
        });
      }
    } finally {
      recaptchaRef.current?.reset();
      setIsLoading(false);
    }
  };

  const handleStepThree = async (data: ForgotPasswordFormTypes) => {
    try {
      if (!recaptchaRef.current) return;
      const token = await recaptchaRef.current.executeAsync();
      if (!token) return;
      setIsLoading(true);
      await updatePasswordOtpService({
        email: data.email,
        ggRecaptchaToken: token,
        code: data.verificationCode,
        password: data.newPassword,
        password_confirmation: data.newPasswordConfirm,
      });
      setNotify({
        isOpen: true,
        isSuccess: true,
        message: t('notify.congratulations_change_pass')
      });
    } catch (error) {
      setNotify({
        isOpen: true,
        isSuccess: false,
        message: getErrors(error),
      });
    } finally {
      recaptchaRef.current?.reset();
      setIsLoading(false);
    }
  };

  const onSubmit = useCallback(async (data: ForgotPasswordFormTypes) => {
    if (step === 1) {
      handleStepOne(data.email);
    }

    if (step === 2) {
      handleStepTwo(data.verificationCode, data.email);
    }

    if (step === 3) {
      handleStepThree(data);
    }
  }, [step]);

  const onHandleContinue = () => {
    setNotify({
      isOpen: false,
      isSuccess: true,
      message: '',
    });
    if (notify.isSuccess) {
      setTimeout(() => {
        navigate(getPathName('AUTH'));
      }, 1000);
    }
  };

  const onHandleSendAgain = () => {
    handleStepOne(mailState);
  };

  return (
    <div className="t-forgotPassword">
      <FormProvider {...method}>
        <form noValidate onSubmit={method.handleSubmit(onSubmit)}>
          {(() => {
            switch (step) {
              case 1: return (
                <Step1
                  step={step}
                  slug={getPathName('AUTH')}
                  textLink={t('system.returnSignIn')}
                  isLoading={isLoading}
                />
              );
              case 2: return (
                <Step2
                  textLink="Send again"
                  disabled={!method.watch('verificationCode')}
                  onHandleSendAgain={onHandleSendAgain}
                  isLoading={isLoading}
                  step={step}
                />
              );
              case 3: return (
                <Step3
                  step={step}
                  isLoading={isLoading}
                />
              );
              default: return null;
            }
          })()}
        </form>
      </FormProvider>
      <Notify
        {...notify}
        onHandleAction={onHandleContinue}
      />
      <Notify
        {...notifyRequestOTP}
        textButtonSuccess={t('notify.ok')}
        onHandleAction={() => setNotifyRequestOTP({
          isOpen: false,
          isSuccess: true,
          message: '',
        })}
      />
      {googleRecaptchaKey
        && (
          <ReCAPTCHA
            sitekey={googleRecaptchaKey}
            size="invisible"
            ref={recaptchaRef}
          />
        )}
    </div>
  );
};

export default ForgotPassword;
