import {
  useEffect,
  useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { NotifyData } from 'components/organisms/Notify';
import { customerStartService, customerStopService } from 'services/auth';
import {
  getProfileAction,
  setLoadingProcessService, updateProcessService
} from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPathName } from 'utils/language';

interface UseActionTimerProps {
  isTimerHeader: boolean;
}

const useActionTimer = ({ isTimerHeader }: UseActionTimerProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const aiServerResult = useAppSelector((state) => state.auth.aiServerResult);
  const isLoadingProcessService = useAppSelector((state) => state.auth.isLoadingProcessService);

  const [notify, setNotify] = useState<NotifyData & { rootCause?: 'durationNotEnough' | '' }>({
    isOpen: false,
    isSuccess: true,
    message: '',
    rootCause: ''
  });

  const { mutate: onHandleStartService } = useMutation(
    ['start-service'],
    () => customerStartService(),
    {
      onError: (error: Array<ErrorAPIResponseTypes>) => {
        dispatch(setLoadingProcessService(false));
        if (error && Array.isArray(error) && error.length > 0) {
          const waiting = error.find((item) => item.code === 'customerIsWaiting');
          if (waiting) {
            dispatch(updateProcessService({ ...aiServerResult, status: 'waiting' }));
          }

          const durationNotEnough = error.find((item) => item.code === 'durationNotEnough');
          if (durationNotEnough) {
            setNotify({
              isOpen: true,
              message: 'Not enough time to use the service! <br /> Please buy to use',
              isSuccess: false,
              rootCause: 'durationNotEnough'
            });
          }
        }
      }
    }
  );

  const { mutate: onHandleStopService } = useMutation(
    ['stop-service'],
    () => customerStopService(),
    {
      onSettled: () => {
        if (aiServerResult.status === 'starting') {
          dispatch(getProfileAction())
            .unwrap()
            .finally(() => {
              dispatch(setLoadingProcessService(false));
            });
        } else {
          dispatch(updateProcessService({
            ...aiServerResult,
            status: 'stopped',
            serverLink: '',
            queue: 0
          }));
          dispatch(setLoadingProcessService(false));
        }
      }
    }
  );

  const onHandleAction = () => {
    if (isTimerHeader && aiServerResult.status !== 'starting') {
      navigate(getPathName('WORKSPACE'));
    }
    if (aiServerResult.duration === 0) return navigate('/', { state: { buyAISection: true } });
    if (aiServerResult.status === 'starting') {
      dispatch(setLoadingProcessService(true));
      if (isTimerHeader) {
        navigate(getPathName('HISTORY'));
      }
      return onHandleStopService();
    }
    if (aiServerResult.status === 'waiting') {
      dispatch(setLoadingProcessService(true));
      return onHandleStopService();
    }
    dispatch(setLoadingProcessService(true));
    return onHandleStartService();
  };

  const detectButtonText = useMemo(() => {
    if (aiServerResult.duration === 0) return t('timer.buy_now');
    if (aiServerResult.status === 'starting') return t('timer.stop');
    if (aiServerResult.status === 'waiting') return t('timer.waiting');
    return t('timer.start');
  }, [aiServerResult, t]);

  useEffect(() => {
    if (aiServerResult && aiServerResult.type === 'AIServerResult') {
      dispatch(setLoadingProcessService(false));
    }
  }, [aiServerResult]);

  useEffect(() => {
    if (!isTimerHeader) {
      dispatch(getProfileAction());
    }
  }, [isTimerHeader]);

  const onHandleClosePopup = () => {
    if (notify.rootCause === 'durationNotEnough') {
      dispatch(getProfileAction());
    }
    setNotify({
      isOpen: false, message: '', isSuccess: true, rootCause: ''
    });
  };

  const isPlayGround = useMemo(() => aiServerResult.status === 'starting' && aiServerResult.serverLink && pathname.includes(getPathName('WORKSPACE')), [aiServerResult, pathname]);

  return {
    isLoadingProcessService,
    detectButtonText,
    notify,
    isPlayGround,
    onHandleAction,
    onHandleClosePopup,
  };
};

export default useActionTimer;
