import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Input, Label,
  Typography
} from 'tfc-components';

import Loading from 'components/atoms/Loading';
import mapModifiers from 'utils/functions';

interface Step2Props {
  textLink?: string,
  disabled?: boolean,
  step?: number,
  onHandleSendAgain?: () => void;
  isLoading: boolean
}
const Step2: React.FC<Step2Props> = ({
  textLink,
  disabled,
  step,
  onHandleSendAgain,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <div className="t-forgotPassword">
      <Label
        extendClasses="t-forgotPassword_label fs-14"
        spacingBottom={12}
        required
      >
        {t('system.verificationCode')}
      </Label>
      <Controller
        name="verificationCode"
        rules={
          step === 2 ? {
            required: `${t('notify.opt_fieldRequired')}`,
          } : {}
        }
        render={({
          field,
          fieldState: { error },
        }) => (
          <>
            <Input
              {...field}
              bordered
              extendClasses="t-forgotPassword_input"
            />
            {error && (
              <div className="t-forgotPassword_error">{error.message}</div>
            )}
          </>
        )}
      />
      <Button
        primaryColor="#0C5DA5"
        hoveredPrimaryColor="#106ec1"
        variant="primary"
        extendClasses={mapModifiers('t-forgotPassword_button', (disabled || isLoading) && 'disabled')}
        type="submit"
        loading={isLoading}
        loadingIndicator={<Loading variant="default" />}
        disabled={isLoading}
      >
        <Typography.Text
          fontweight="600"
          textStyle="uppercase"
          extendClasses="t-forgotPassword_button-text fs-16"
        >
          {t('system.resetPassword')}
        </Typography.Text>
      </Button>
      <div className="t-forgotPassword_wrapLink">
        <div className="t-forgotPassword_wrapLink_text">
          <Typography.Text
            extendClasses="fs-14"
          >
            {t('system.didnotReceiveMail')}
          </Typography.Text>
        </div>
        <div className="t-forgotPassword_reSendBtn" onClick={onHandleSendAgain}>
          <Typography.Text
            extendClasses="color-mediumPersianBlue fs-14"
            textStyle="underline"
          >
            {textLink}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Step2);
