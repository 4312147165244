import { AxiosError } from 'axios';

import { CONSTANT_LANGUAGE_LIST } from './constants';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const baseURL = (src?: string): string => `${BASE_URL || ''}${src || ''}`;

export const mappingURLToExternal = (path?: string) => (path ? new URL(path, window.location.origin).href : '');

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop - 68 },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getLocaleFromPath = (pathname: string) => {
  const prefixLang = pathname.split('/')[1];
  if (CONSTANT_LANGUAGE_LIST.includes(prefixLang)) {
    return prefixLang;
  }
  return undefined;
};

export const checkExternalUrl = (str?: string) => {
  if (!str) return false;
  const tareaRegex = /^(http|https|tel)/;
  return tareaRegex.test(String(str).toLowerCase());
};

export const checkTypeErrors = (error: unknown): Array<ErrorAPIResponseTypes> => {
  const errors = error instanceof AxiosError
    && error.response?.data ? (error.response?.data as any)?.errors : false;

  if (errors && Array.isArray(errors)) {
    return errors;
  }
  if (error && Array.isArray(error)) {
    return error;
  }
  return [];
};

export const getErrors = (error: unknown) => {
  const message: string[] = [];
  const errors = checkTypeErrors(error);

  if (errors && errors.length > 0) {
    errors.forEach((item: ErrorAPIResponseTypes) => {
      message.push(item?.message || item?.title);
    });
    return message.join(', ');
  }
  return 'Failed. Please try again later.';
};

export const convertMinutes = (minute: number) => {
  if (minute > 1) {
    return `${minute} minutes`;
  }
  return `${minute} minute`;
};

export const formatDateTime = (time?: string): string => {
  if (!time) return '';
  const dateData = new Date(time);
  const date = dateData.getDate() < 10 ? `0${dateData.getDate()}` : dateData.getDate();
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const monthText = months[dateData.getMonth()];
  const year = dateData.getFullYear().toString();
  return `${monthText} ${date}, ${year}`;
};

export function getParams(): { [key: string]: string } {
  return window.location.search
    .split(/[?&]/)
    .map((s) => s.split('='))
    .filter((a) => a.length === 2)
    .map(([k, v]) => [decodeURIComponent(k), decodeURIComponent(v)])
    .reduce(
      (a, [k, v]) => ({
        ...a,
        [k]: v,
      }),
      {},
    );
}
