import React from 'react';
import { Button, Image, Typography } from 'tfc-components';

import CustomModal from '../Modal';

import imageNotification from 'assets/images/imageNotification.png';

export interface NotifyData {
  message: string;
  isOpen: boolean;
  isSuccess?: boolean;
  textButtonSuccess?: string;
}

interface NotifyProps extends NotifyData {
  onHandleAction?: () => void;
}

const Notify: React.FC<NotifyProps> = ({
  message, onHandleAction, isOpen, isSuccess, textButtonSuccess
}) => (
  <div className="o-notify">
    <CustomModal isOpen={isOpen} variant="maxWith464">
      <div className="o-notify_imgWrapper">
        <Image
          ratio={252 / 24}
          imgSrc={imageNotification}
          alt="Notification"
          extendClasses="o-notify_imgWrapper-logo"
        />
      </div>
      <div className={`o-notify_message color-${isSuccess ? 'mediumPersianBlue' : 'cadmiumRed'}`} dangerouslySetInnerHTML={{ __html: message }} />
      <Button
        extendClasses="o-notify_actionWrapper"
        onClick={onHandleAction}
      >
        <Typography.Text
          fontweight="500"
          textStyle="underline"
          extendClasses="o-notify_action"
        >
          {isSuccess ? textButtonSuccess || 'Continue' : 'Try again'}
        </Typography.Text>
      </Button>
    </CustomModal>
  </div>
);

export default Notify;
