import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Input, Label, Link, Typography
} from 'tfc-components';

import Loading from 'components/atoms/Loading';
import mapModifiers from 'utils/functions';

interface Step1Props {
  slug?: string
  textLink?: string,
  step: number
  isLoading: boolean
}
const Step1: React.FC<Step1Props> = ({
  slug,
  textLink,
  step,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <div className="t-forgotPassword">
      <Label
        extendClasses="t-forgotPassword_label fs-14"
        spacingBottom={12}
        required
      >
        {t('system.emailPhone')}
      </Label>
      <Controller
        name="email"
        rules={
          step === 1 ? {
            required: `${t('notify.provideInformation')}`,
            validate: {
              matchPattern: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)
                || `${t('notify.email_incorrect')}`,
            }
          } : {}
        }
        render={({
          field: { value, onChange },
          fieldState: { error },
        }) => (
          <>
            <Input
              bordered
              extendClasses="t-forgotPassword_input"
              value={value}
              onChange={onChange}
            />
            {error && (
              <div className="t-forgotPassword_error">{error.message}</div>
            )}
          </>
        )}
      />
      <Button
        primaryColor="#0C5DA5"
        hoveredPrimaryColor="#106ec1"
        variant="primary"
        extendClasses={mapModifiers('t-forgotPassword_button', isLoading && 'disabled')}
        type="submit"
        loading={isLoading}
        loadingIndicator={<Loading variant="default" />}
        disabled={isLoading}
      >
        <Typography.Text
          fontweight="600"
          textStyle="uppercase"
          extendClasses="t-forgotPassword_button-text fs-16"
        >
          {t('system.request')}
        </Typography.Text>
      </Button>
      <Link
        href={slug}
        extendClasses="t-forgotPassword_link"
      >
        <Typography.Text
          type="span"
          extendClasses="color-mediumPersianBlue fs-14"
        >
          {textLink}
        </Typography.Text>
      </Link>
    </div>
  );
};

export default React.memo(Step1);
