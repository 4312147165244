import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Input, Label, Typography
} from 'tfc-components';

import Loading from 'components/atoms/Loading';
import mapModifiers from 'utils/functions';

interface Step3Props {
  step?: number;
  isLoading: boolean;
}
const Step3: React.FC<Step3Props> = ({
  step,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <div className="t-forgotPassword">
      <Label
        extendClasses="t-forgotPassword_label fs-14"
        spacingBottom={12}
        required
      >
        {t('system.newPassword')}
      </Label>
      <Controller
        name="newPassword"
        rules={
          step === 3 ? {
            required: t('notify.fieldRequired'),
            validate: {
              minLength: (value) => value.length >= 8 || t('notify.minPassword'),
              maxLength: (value) => value.length <= 26 || t('notify.maxPassword'),
              checkOldPass: (value, formValues) => {
                if (value !== formValues?.newPassword) return `${t('system.password_not_match_confirm_password')}`;
                return true;
              }
            }
          } : {}
        }
        render={({
          field: { value, onChange },
          fieldState: { error },
        }) => (
          <>
            <Input
              type="password"
              bordered
              extendClasses="t-forgotPassword_input"
              value={value}
              onChange={onChange}
            />
            {error && (
              <div className="t-forgotPassword_error">{error.message}</div>
            )}
          </>
        )}
      />
      <div className="t-forgotPassword_wrapInput">
        <Label
          extendClasses="t-forgotPassword_label fs-14"
          spacingBottom={12}
        >
          {t('system.confirmNewPassword')}
        </Label>
        <Controller
          name="newPasswordConfirm"
          rules={
            step === 3 ? {
              required: `${t('notify.fieldRequired')}`,
              validate: {
                minLength: (value) => value.length >= 8 || t('notify.minPassword'),
                maxLength: (value) => value.length <= 26 || t('notify.maxPassword'),
                checkOldPass: (value, formValues) => value === formValues?.newPassword || `${t('notify.matchConfirm')}`
              }
            } : {}
          }
          render={({
            field: { value, onChange },
            fieldState: { error },
          }) => (
            <>
              <Input
                bordered
                type="password"
                extendClasses="t-forgotPassword_input"
                value={value}
                onChange={onChange}
              />
              {error && (
                <div className="t-forgotPassword_error">{error.message}</div>
              )}
            </>
          )}
        />
      </div>
      <Button
        primaryColor="#0C5DA5"
        hoveredPrimaryColor="#106ec1"
        variant="primary"
        extendClasses={mapModifiers('t-forgotPassword_button', isLoading && 'disabled')}
        type="submit"
        loading={isLoading}
        loadingIndicator={<Loading variant="default" />}
        disabled={isLoading}
      >
        <Typography.Text
          fontweight="600"
          textStyle="uppercase"
          extendClasses="t-forgotPassword_button-text fs-16"
        >
          RESET PASSWORD
        </Typography.Text>
      </Button>
    </div>
  );
};

export default React.memo(Step3);
