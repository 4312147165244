import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { getPathName } from 'utils/language';

interface ProtectedRouteProps {
  children?: any;
  path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  path
}) => {
  const { isLogout, profile } = useAppSelector((state) => state.auth);

  if (!profile && !isLogout) return <Navigate to={getPathName('AUTH')} state={{ path }} />;

  return children;
};

export default ProtectedRoute;
