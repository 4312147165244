import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authReducer from './auth';
import exampleReducer from './example';
import menuReducer from './menus';
import paymentReducer from './payment';
import privacyPolicyReducer from './privacy';
import systemsReducer from './system';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    example: exampleReducer,
    systems: systemsReducer,
    auth: authReducer,
    payment: paymentReducer,
    privacyPolicy: privacyPolicyReducer,
    menus: menuReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
