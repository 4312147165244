/* eslint-disable prefer-regex-literals */
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const useSchemas = () => {
  const { t } = useTranslation();

  const signUp = yup.object().shape({
    email: yup.string().required(`${t('notify.provideInformation')}`).email(`${t('notify.invalidEmail')}`),
    phone: yup.string().test('phone', `${t('notify.invalidFormat')}`, (value) => {
      if (value && !value.match(phoneRegExp)) return false;
      return true;
    }).default(undefined),
    password: yup.string()
      .required(`${t('notify.provideInformation')}`)
      .min(8, t('notify.minPassword'))
      .max(26, t('notify.maxPassword')),
    password_confirmation: yup.string()
      .required(`${t('notify.provideInformation')}`)
      .min(8, t('notify.minPassword'))
      .max(26, t('notify.maxPassword'))
      .oneOf([yup.ref('password')], 'Confirm password must match'),
    address: yup.string(),
    countryCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined).nullable(),
    provinceCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required()
    }).default(undefined).nullable(),
    agree: yup.bool().default(true).oneOf([true], t('notify.agreedPolicy'))
  });

  const login = yup.object().shape({
    emailOrPhone: yup.string().required(`${t('notify.provideInformation')}`),
    password: yup.string().required(`${t('notify.provideInformation')}`)
  });

  const updateProfile = yup.object().shape({
    name: yup.string(),
    bod: yup.string().test('validBirthYear', `${t('notify.validBirthYear')}`, (value) => {
      if (value && Number(value) < 1900) return false;
      return true;
    }),
    gender: yup.object().shape({
      value: yup.number().required(),
      label: yup.string().required()
    }).nullable(),
    email: yup.string().default('').email(`${t('notify.invalidEmail')}`).nullable(),
    phone: yup.string().test('phone', `${t('notify.invalidFormat')}`, (value) => {
      if (value && !value.match(phoneRegExp)) return false;
      return true;
    }).default(undefined),
    password: yup.string().default('')
      .test('minPassword', `${t('notify.minPassword')}`, (value) => {
        if (value && value?.length < 8) return false;
        return true;
      }),
    countryCode: yup.object({
      value: yup.string(),
      label: yup.string()
    }).default(undefined),
    provinceCode: yup.object({
      value: yup.string(),
      label: yup.string()
    }).default(undefined),
  });

  const updateBilling = yup.object().shape({
    name: yup.string().required(`${t('notify.provideInformation')}`),
    email: yup.string().default('').email(`${t('notify.invalidEmail')}`)
      .required(`${t('notify.provideInformation')}`),
    phone: yup.string().test('phone', `${t('notify.invalidFormat')}`, (value) => {
      if (value && !value.match(phoneRegExp)) return false;
      return true;
    }).default(undefined).required(`${t('notify.provideInformation')}`),
    company: yup.string().default(''),
    countryCode: yup.object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .default(undefined)
      .required(`${t('notify.provideInformation')}`),
    provinceCode: yup.object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .default(undefined)
      .required(`${t('notify.provideInformation')}`),
  });

  const schemaContactForm = yup.object({
    name: yup.string().required(`${t('notify.provideInformation')}`),
    phone: yup.string().required(`${t('notify.provideInformation')}`).matches(phoneRegExp, `${t('notify.invalidFormat')}`),
    email: yup.string().required(`${t('notify.provideInformation')}`).email(`${t('notify.invalidEmail')}`),
    content: yup.string().default(undefined),
  });

  const schemasChangePasswordForm = yup.object().shape({
    currentPassword: yup.string().required(t('notify.provideInformation')),
    password: yup.string()
      .required(`${t('notify.provideInformation')}`)
      .min(8, t('notify.minPassword'))
      .max(26, t('notify.maxPassword')),
    password_confirmation: yup.string()
      .required(t('notify.the_new_password_must_be_the_same_as_the_old_password'))
      .min(8, t('notify.minPassword'))
      .max(26, t('notify.maxPassword'))
      .oneOf([yup.ref('password')], t('notify.the_new_password_is_not_the_same_as_the_old_password'))
  });

  return {
    signUp,
    login,
    updateProfile,
    schemaContactForm,
    schemasChangePasswordForm,
    updateBilling
  };
};

export default useSchemas;
