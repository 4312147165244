import React from 'react';

import AuthLayout from 'components/templates/AuthLayout';
import ForgotPasswordTemplate from 'components/templates/ForgotPassword';
import HelmetContainer from 'components/templates/Mainlayout/helmet';

const ForgotPassword: React.FC = () => (
  <div className="p-forgotPassword">
    <HelmetContainer />
    <AuthLayout>
      <ForgotPasswordTemplate />
    </AuthLayout>
  </div>
);

export default ForgotPassword;
