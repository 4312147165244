import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getPagePrivacyPolicyService } from 'services/pages';
import { PrivacyPolicyData } from 'services/pages/types';

interface PrivacyPolicyState {
  data?: PrivacyPolicyData;
  isLoading: boolean;
}

const initialState: PrivacyPolicyState = {
  data: undefined,
  isLoading: false,
};

export const getPrivacyPolicyAction = createAsyncThunk(
  'privacyPolicyReducer/getPrivacyPolicyService',
  async (slug: string, { rejectWithValue }) => {
    try {
      const res = (await getPagePrivacyPolicyService(slug));
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const privacyPolicySlice = createSlice({
  name: 'privacyPolicyReducer',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(getPrivacyPolicyAction.pending, ($state) => {
      $state.isLoading = true;
    });
    builder.addCase(getPrivacyPolicyAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
      $state.isLoading = false;
    });
    builder.addCase(getPrivacyPolicyAction.rejected, ($state) => {
      $state.isLoading = false;
    });
  },
});

export default privacyPolicySlice.reducer;
