import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderData } from 'components/templates/Payment/totalPayment';
import { removeLocalStorage, setLocalStorage } from 'services/common/storage';
import { LOCAL_STORAGE } from 'utils/constants';

interface AuthState {
  data?: OrderData,
}

const initialState: AuthState = {
  data: undefined,
};

export const paymentSlice = createSlice({
  name: 'paymentReducer',
  initialState,
  reducers: {
    updateInfoPayment($state, action: PayloadAction<OrderData>) {
      setLocalStorage(LOCAL_STORAGE.PAYMENT, JSON.stringify(action.payload));
      $state.data = action.payload;
    },
    removeInfoPayment($state) {
      removeLocalStorage(LOCAL_STORAGE.PAYMENT);
      $state.data = undefined;
    },
  }
});

export const { updateInfoPayment, removeInfoPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
