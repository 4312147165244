import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import {
  Controller, FormProvider, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, Input, Label, Link, Typography
} from 'tfc-components';

import Loading from 'components/atoms/Loading';
import LoginSocials from 'components/organisms/LoginSocials';
import { loginService } from 'services/auth';
import { getLocalStorage, setAccessToken, setRefreshToken } from 'services/common/storage';
import { getProfileAction } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import { LOCAL_STORAGE } from 'utils/constants';
import { getPathName } from 'utils/language';
import useSchemas from 'utils/schemas';

export type FormReturnTypes = {
  emailOrPhone: string;
  password: string;
};

interface LoginFormProps {
  forgotPasswordHref?: string;
  handleSignUp(): void;
  handleLoginSuccess?(response: any): void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  forgotPasswordHref, handleSignUp
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const statePath = state as { path?: string };
  const { t } = useTranslation();
  const { login } = useSchemas();

  const method = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(login),
    defaultValues: {
      emailOrPhone: '',
      password: '',
    }
  });

  const { mutate: loginMutate, isLoading } = useMutation(
    ['loginMutate'],
    (data: FormReturnTypes) => loginService(data),
    {
      onSuccess(data) {
        const { accessToken, refreshToken } = data;
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        dispatch(getProfileAction()).unwrap().then((res) => {
          const usageTimeSession = getLocalStorage(LOCAL_STORAGE.USAGE_TIME_SESSION);
          const isFocusGoToWorkSpace = getLocalStorage(LOCAL_STORAGE.IS_FOCUS);
          if (isFocusGoToWorkSpace) {
            navigate(`${getPathName('WORKSPACE')}?isFocus=true`, { replace: true });
          } else if (usageTimeSession) {
            navigate(getPathName('PAYMENT'), { replace: true });
          } else if (res.customerData && res.customerData.duration > 0) {
            navigate(getPathName('WORKSPACE'), { replace: true });
          } else {
            navigate(statePath?.path || '/', { replace: true });
          }
        });
      },
      onError(error: ErrorAPIResponseTypes[]) {
        if (error.length) {
          switch (error[0].code) {
            case 'validate_001':
              method.setError('emailOrPhone', { message: t('notify.email_incorrect') });
              method.setError('password', { message: t('notify.password_incorrect') });
              break;
            default:
              method.setError('password', { message: t('notify.password_incorrect') });
              break;
          }
        }
      }
    }
  );

  return (
    <div className="t-login">
      <FormProvider {...method}>
        <form onSubmit={method.handleSubmit((data) => loginMutate(data))}>
          <div className="t-login_field">
            <Label extendClasses="fs-14" required>{t('system.emailPhone')}</Label>
            <Controller
              name="emailOrPhone"
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    extendClasses={`t-login_input${error ? ' t-login_input-error' : ''}`}
                    {...field}
                  />
                  {error && (
                    <div className="t-login_error">{error.message}</div>
                  )}
                </>
              )}
            />
          </div>
          <div className="t-login_field u-mt-16">
            <Label extendClasses="fs-14" required>{t('system.password')}</Label>
            <Controller
              name="password"
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    type="password"
                    extendClasses={`t-login_input${error ? ' t-login_input-error' : ''}`}
                    {...field}
                  />
                  {error && (
                    <div className="t-login_error">{error.message}</div>
                  )}
                </>
              )}
            />
          </div>
          <div className="t-login_forgotPassword">
            <Link href={forgotPasswordHref}>
              <Typography.Text type="span" extendClasses="fs-14 color-mediumPersianBlue">
                {t('system.forgotPassword')}
              </Typography.Text>
            </Link>
          </div>
          <div className="t-login_submit">
            <Button
              variant="primary"
              primaryColor="#0C5DA5"
              hoveredPrimaryColor="#106EC1"
              type="submit"
              loading={isLoading}
              loadingIndicator={<Loading variant="default" />}
              disabled={isLoading}
            >
              <Typography.Text textStyle="uppercase" fontweight="600">
                {t('system.logIn')}
              </Typography.Text>
            </Button>
          </div>
          <LoginSocials />
          <div className="t-login_signUp">
            <Typography.Text extendClasses="fs-14">{t('system.donotHaveAccount')}</Typography.Text>
            <div className="t-login_signUp_link" role="button" onClick={handleSignUp}>
              <Typography.Text extendClasses="fs-14 color-mediumPersianBlue">{t('system.signUp')}</Typography.Text>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default LoginForm;
