import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { OgDataItemType } from 'services/pages/types';
import { useAppSelector } from 'store/hooks';
import { baseURL, mappingURLToExternal } from 'utils/functions';

interface Props {
  seoData?: SEOData;
  ogData?: { [key: string]: OgDataItemType; };
  children?: React.ReactNode;
}
const HelmetContainer: React.FC<Props> = ({ seoData, ogData, children }) => {
  const seo = useAppSelector((state) => state.systems.system?.seoData);
  const ogImageDefault = useAppSelector(
    (state) => state.systems.header?.blocks.section.logoSrc.data.path,
  );

  const custom = useMemo(() => ({
    title: seoData?.title || seo?.title,
    description: seoData?.description || seo?.description,
    keywords: seoData?.keywords || seo?.keywords,
    canonical: seoData?.canonicalUrl,
    noIndex: seoData?.noIndex,
    ogTitle: ogData?.facebook?.title || seoData?.title || seo?.title,
    ogDescription: ogData?.facebook?.description || seoData?.description || seo?.description,
    ogImage: baseURL(ogData?.facebook?.image || '') || baseURL(ogImageDefault),
    twTitle: ogData?.twitter?.title || seoData?.title || seo?.title,
    twDescription: ogData?.twitter?.description || seoData?.title || seo?.title,
  }), [seo, seoData, ogData, ogImageDefault]);

  const removePrefixCanonical = (link: string) => (link.substring(link.length - 1) === '/' ? link.substring(0, link.length - 1) : link);

  return (
    <Helmet>
      <link rel="alternate" href={window.location.href} hrefLang="vi-vn" />
      {custom?.title && <title>{custom.title}</title>}
      {custom?.description && <meta name="description" content={custom.description} />}
      {custom?.keywords && <meta name="keyword" content={custom.keywords} />}
      <link rel="canonical" href={removePrefixCanonical(custom.canonical || window.location.origin + window.location.pathname)} />
      <meta property="og:url" content={window.location.href} />
      {custom?.noIndex && <meta name="robots" content="noindex" />}
      {custom.ogTitle && <meta property="og:title" content={custom.ogTitle} />}
      {custom.ogDescription && <meta property="og:description" content={custom.ogDescription} />}
      {custom.ogImage && <meta property="og:image" content={mappingURLToExternal(custom.ogImage)} />}
      {custom.twTitle && <meta name="twitter:title" content={custom.twTitle} />}
      {custom.twDescription && <meta name="twitter:description" content={custom.twDescription} />}
      {children}
    </Helmet>
  );
};

HelmetContainer.defaultProps = {
  seoData: undefined,
  ogData: undefined,
};

export default HelmetContainer;
