import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar, Button,
  Link, Popover, Typography
} from 'tfc-components';

import ModalConfirmLeave from '../ModalConfirmLeave';
import Notify from '../Notify';

import playgroundFloating from 'assets/icons/ic_ai_header.svg';
import Icon, { IconName } from 'components/atoms/Icon';
import Loading from 'components/atoms/Loading';
import CountdownTimer from 'components/molecules/CountdownTimer';
import Container from 'components/organisms/Container';
import useActionTimer from 'hooks/useActionTimer';
import useCallbackPrompt from 'hooks/useCallbackPrompt';
import { useAppSelector } from 'store/hooks';
import { CONSTANT_TAB_AUTH } from 'utils/constants';
import mapModifiers from 'utils/functions';
import { getPathName } from 'utils/language';

export type ActionTypes = {
  icon: IconName;
  iconActive: IconName;
  title: string;
  pathname: string;
  handleClick(): void;
};
interface HeaderProps {
  logoSrc?: string;
  userInfo: {
    name?: string;
    email?: string;
  };
  actionList: ActionTypes[];
  isLogin?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  logoSrc,
  userInfo,
  actionList,
  isLogin,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { duration, status } = useAppSelector((state) => state.auth.aiServerResult);
  const statusSocket = useAppSelector((state) => state.auth.statusSocket);

  const [isOpen, setIsOpen] = useState(false);
  const { confirmNavigation, cancelNavigation } = useCallbackPrompt(isOpen);

  const {
    detectButtonText, isLoadingProcessService, notify, onHandleAction, onHandleClosePopup
  } = useActionTimer({ isTimerHeader: true });

  const handleBuyAI = (): void => {
    navigate('/', { state: { buyAISection: true } });
  };

  const onHandleActionService = () => {
    if (status === 'starting' && pathname.includes(getPathName('WORKSPACE'))) {
      setIsOpen(true);
    } else {
      onHandleAction();
    }
  };

  return (
    <header className="o-header">
      <Container>
        <div className="o-header_wrapper">
          <div className="o-header_left">
            <Link href="/" aria-label="Logo on header">
              <Typography.Heading type="h1">
                <img width={185} height={13} src={logoSrc || ''} alt="Logo Cloud Diffusion" />
              </Typography.Heading>
            </Link>
          </div>
          <div className="o-header_right">
            {
              isLogin && (
                <div
                  className="o-header_counter"
                  onClick={() => {
                    navigate(getPathName('WORKSPACE'));
                  }}
                >
                  <div className="o-header_counter_icon">
                    <Icon iconName="timerActive" size="24" />
                  </div>
                  <div className="o-header_counter_timer">
                    <CountdownTimer />
                  </div>
                </div>
              )
            }

            {
              isLogin && (
                <div>
                  <Button loading={isLoadingProcessService} disabled={isLoadingProcessService} loadingIndicator={<Loading variant="default" width={30} />} extendClasses="t-timer_button u-ml-16" onClick={onHandleActionService}>
                    <Typography.Text fontweight="500" extendClasses="fs-16-sm color-mediumPersianBlue">{detectButtonText}</Typography.Text>
                  </Button>
                </div>
              )
            }
            {
              duration !== 0 && isLogin && (
                <Button extendClasses="o-header_buttonBuyMore u-ml-16" onClick={handleBuyAI}>
                  <Typography.Text fontweight="500" extendClasses="fs-16-sm color-mediumPersianBlue">{t('timer.buy_now')}</Typography.Text>
                </Button>
              )
            }
            {
              isLogin && (
                <Button
                  extendClasses="o-header_buttonAI u-ml-16"
                  onClick={() => {
                    navigate(getPathName('WORKSPACE'));
                  }}
                >
                  <img width={21} height={21} alt="floating playground" src={playgroundFloating} />
                </Button>
              )
            }
            {
              isLogin ? (
                <div className="o-header_popover">
                  <Popover
                    extendClasses="o-header_popover_wrap"
                    align="right-bottom"
                  >
                    <Popover.Trigger>
                      <div className="o-header_popover_inner">
                        <div className="o-header_popover_avatar">
                          <Avatar
                            shape="circle"
                            name={userInfo.name}
                            extendClasses="color-white"
                          />
                        </div>
                        <div className="o-header_popover_mail">
                          <Typography.Text extendClasses="fs-14">
                            {userInfo.email || userInfo.name}
                          </Typography.Text>
                        </div>
                        <div className={mapModifiers('o-header_popover_status', statusSocket)} />
                        <Icon iconName="arrowDown" size="24" />
                      </div>
                    </Popover.Trigger>
                    <Popover.Content extendClasses="o-header_popover_content">
                      {actionList.length > 0 && (
                        <ul className="o-header_popover_actionList">
                          {actionList.map((item, idx) => (
                            <li
                              key={`action-item-${idx.toString()}`}
                              className={mapModifiers('o-header_actionList_item', pathname.includes(item.pathname || '') ? 'active' : '')}
                              onClick={item.handleClick}
                            >
                              <div className="o-header_actionList_item_icon">
                                <Icon iconName={pathname.includes(item.pathname || '') ? item.iconActive : item.icon} size="24" />
                              </div>
                              <div className="o-header_actionList_item_title">
                                <Typography.Text extendClasses="fs-16">{item.title}</Typography.Text>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Popover.Content>
                  </Popover>
                </div>
              ) : (
                <div className="o-header_wrapButton">
                  <Button
                    extendClasses="o-header_wrapButton_button"
                    onClick={() => navigate(getPathName('AUTH'), { state: { auth: CONSTANT_TAB_AUTH.LOGIN } })}
                  >
                    <Typography.Text fontweight="500">
                      Log In
                    </Typography.Text>
                  </Button>
                  /
                  <Button
                    extendClasses="o-header_wrapButton_button"
                    onClick={() => navigate(getPathName('AUTH'), { state: { auth: CONSTANT_TAB_AUTH.SIGNUP } })}
                  >
                    <Typography.Text fontweight="500">
                      Sign Up
                    </Typography.Text>
                  </Button>
                </div>
              )
            }
          </div>
        </div>
      </Container>
      <Notify {...notify} onHandleAction={onHandleClosePopup} />
      <ModalConfirmLeave
        isShowPrompt={isOpen}
        isConfirmStop
        confirmNavigation={() => {
          confirmNavigation();
          setIsOpen(false);
          onHandleAction();
        }}
        cancelNavigation={() => {
          setIsOpen(false);
          cancelNavigation();
        }}
      />
    </header>
  );
};
export default Header;
