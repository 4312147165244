import type { History, Blocker, Transition } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export type AutoUnBlockingTypes = Transition & { retry(): void };

const useBlocker = (blocker: Blocker, when = true): void => {
  const navigator = React.useContext(UNSAFE_NavigationContext)
    .navigator as History;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx: AutoUnBlockingTypes = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
};

export default useBlocker;
