import { useEffect } from 'react';

import { postAllowNotifyService } from 'services/auth';
import { useAppSelector } from 'store/hooks';

const useServiceWorker = () => {
  const webpushId = useAppSelector((state) => state.systems.system?.others.webpushId);
  const profile = useAppSelector((state) => state.auth.profile);

  function urlBase64ToUint8Array(base64String: any) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; i += 1) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  function subscribeUser() {
    navigator.serviceWorker.ready
      .then((registration) => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(webpushId)
        };
        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then((pushSubscription) => {
        const parsedPushSubscription = JSON.parse(JSON.stringify(pushSubscription));
        postAllowNotifyService({
          endpoint: parsedPushSubscription.endpoint,
          keys: {
            ...parsedPushSubscription.keys
          }
        });
      });
  }

  function initPush() {
    if (!navigator.serviceWorker.ready) {
      return;
    }
    new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });
      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    })
      .then((permissionResult) => {
        if (permissionResult !== 'granted') {
          throw new Error('We weren\'t granted permission.');
        }
        subscribeUser();
      });
  }

  function initSW() {
    if (!('serviceWorker' in navigator)) {
      // service worker isn't supported
      return;
    }
    // don't use it here if you use service worker
    // for other stuff.
    if (!('PushManager' in window)) {
      // push isn't supported
      return;
    }
    // register the service worker
    navigator.serviceWorker.register('./sw.js')
      .then(() => {
        console.log('serviceWorker installed!');
        // navigator.serviceWorker.controller?.postMessage({
        //   type: 'storeUrl',
        //   url: window.location.origin
        // });
        initPush();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (webpushId && profile) {
      initSW();
    }
  }, [webpushId, profile?.customerData.email, profile?.customerData.phone]);
};

export default useServiceWorker;
