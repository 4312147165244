const chatZaloSDK = (idZalo: string) => {
  try {
    const zaloChat = document.getElementById('zalo-chat-widget');
    zaloChat?.setAttribute('data-oaid', idZalo);

    const script = document.createElement('script');
    // Load the Zalo Chat Widget script
    script.src = 'https://sp.zalo.me/plugins/sdk.js';
    script.async = true;
    document.body.appendChild(script);

    if ((window as any).ZaloSocialSDK) {
      (window as any).ZaloSocialSDK.init({
        app_id: idZalo,
      });
      (window as any).ZaloSocialSDK?.reload();
    }
  } catch (e) {
    // throw error sdk FB
  }
};

export default chatZaloSDK;
