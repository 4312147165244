import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, Typography } from 'tfc-components';

import Container from 'components/organisms/Container';
import { MenuItemTypes } from 'services/menus/types';
import { checkExternalUrl } from 'utils/functions';

// type SocialItemTypes = {
//   imgSrc: string;
//   href: string;
//   target: string;
// };
interface FooterProps {
  policyText?: TextPageTypes;
  // socials: SocialItemTypes[],
  logo?: UploadImagePageTypes,
  address?: TextPageTypes,
  licenseNo?: TextPageTypes,
  nameCompany?: TextPageTypes,
  menu?: MenuItemTypes[],
}

const Footer: React.FC<FooterProps> = ({
  policyText, address, licenseNo, logo, menu, nameCompany
}) => (
  <footer className="o-footer">
    <Container>
      <Row className="o-footer_wrapper">
        <Col md={4} className="o-footer_left">
          <div className="o-footer_col_logo">
            <Link href="/">
              <img
                alt={logo?.data?.path || logo?.data?.title || 'CD'}
                src={logo?.data?.path || ''}
                width={185}
                height={26}
              />
            </Link>
          </div>
          <div className="u-mt-12">
            {nameCompany?.data && <Typography.Text extendClasses="o-footer_left_address color-white u-mb-4" fontweight="400">{nameCompany.data}</Typography.Text>}
            {address?.data && <Typography.Text extendClasses="o-footer_left_address color-white u-mb-4" fontweight="400">{address.data}</Typography.Text>}
            {licenseNo?.data && <Typography.Text extendClasses="o-footer_left_licenseNo color-white" fontweight="400">{licenseNo.data}</Typography.Text>}
          </div>
        </Col>
        <Col md={8}>
          <ul className="o-footer_col_right">
            {menu && menu.length > 0 && menu.map((item, idx) => (
              <li key={`${idx.toString()}`} className="o-footer_col_right_item">
                <div className="o-footer_subTitle">
                  <Link
                    useExternal={checkExternalUrl(item.reference?.slug || item.link)}
                    href={item.reference?.slug || item.link}
                    target={item.target}
                  >
                    <Typography.Text extendClasses="o-footer_menu fs-14 color-gainsboro-ada" fontweight="400">
                      {item.title}
                    </Typography.Text>
                  </Link>
                </div>
                <ul>
                  {item?.children?.map((sub, index) => (
                    <li key={`link-${index.toString()}`}>
                      <Link
                        useExternal={checkExternalUrl(sub.reference?.slug || sub.link)}
                        href={sub.reference?.slug || sub.link}
                        target={item.target}
                      >
                        <Typography.Text extendClasses="o-footer_menu fs-12 color-gainsboro-ada" fontweight="400">
                          {sub.title}
                        </Typography.Text>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <div className="o-footer_bottom">
        <Typography.Text extendClasses="fs-16 color-white" fontweight="500" textStyle="center">
          {policyText?.data}
        </Typography.Text>
      </div>
    </Container>
  </footer>
);

export default Footer;
