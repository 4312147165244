import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getProvincesService, getCountriesService } from 'services/location';

interface Props {
  codeCountry?: string;
}

interface Response {
  countries: {
    options: OptionType[];
    loading?: boolean;
  };
  provinces: {
    options: OptionType[];
    loading?: boolean;
  };
}

const useAddress = ({ codeCountry }: Props): Response => {
  const [countries, setCountries] = useState<OptionType[]>([]);
  const [provinces, setProvinces] = useState<OptionType[]>([]);

  const { isLoading: loadingCountry } = useQuery(
    ['get', 'country'],
    () => getCountriesService(),
    {
      onSuccess: (res) => {
        setCountries(res.map((item) => ({
          label: item.countryData.name || '',
          value: item.countryData.code,
          id: item.countryData.id,
        })));
      }
    }
  );

  const {
    mutate: mutateProvince,
    isLoading: loadingProvince
  } = useMutation(getProvincesService, {
    onSuccess: (res) => {
      setProvinces(res.map((item) => ({
        label: item.provinceData.name || '',
        value: item.provinceData.code,
        id: item.provinceData.id,
      })));
    }
  });

  useEffect(() => {
    if (codeCountry) {
      setProvinces([]);
      mutateProvince(codeCountry);
    }
  }, [codeCountry, mutateProvince]);

  return {
    countries: {
      options: countries,
      loading: loadingCountry,
    },
    provinces: {
      options: provinces,
      loading: loadingProvince,
    },
  };
};

export default useAddress;
