import React, {
  lazy,
  useMemo,
} from 'react';

import { LanguageKeys } from 'services/pages/types';
import { useAppSelector } from 'store/hooks';
import FN_LANGUAGE from 'utils/language';

const HomeNav = lazy(() => import('navigations/HomeNavigate'));
const PageNav = lazy(() => import('navigations/PageNavigate'));
const Payment = lazy(() => import('pages/Payment'));
const Profile = lazy(() => import('pages/Profile'));
const PaymentSuccess = lazy(() => import('pages/PaymentSuccess'));
const PaymentFailed = lazy(() => import('pages/PaymentFailed'));
const Playground = lazy(() => import('pages/Playground'));
const LoginGoogle = lazy(() => import('pages/LoginGoogle'));

const useRoutesList = () => {
  const { system } = useAppSelector((state) => state.systems);

  const langList = useMemo(() => {
    if (system && system?.locales) {
      return Object.keys(system?.locales) as LanguageKeys;
    }
    return [];
  }, [system]);

  const routes = useMemo(
    () => ({
      home: {
        path: FN_LANGUAGE.convertHomeRoute(langList),
        element: <HomeNav />,
        isProtected: false,
      },
      profile: {
        path: FN_LANGUAGE.convertStaticRoute('PROFILE', langList, true),
        element: <Profile />,
        isProtected: true,
      },
      order: {
        path: FN_LANGUAGE.convertStaticRoute('ORDER', langList, true),
        element: <Profile />,
        isProtected: true,
      },
      playground: {
        path: FN_LANGUAGE.convertStaticRoute('WORKSPACE', langList, true),
        element: <Playground />,
        isProtected: true,
      },
      history: {
        path: FN_LANGUAGE.convertStaticRoute('HISTORY', langList, true),
        element: <Profile />,
        isProtected: true,
      },
      billingInfo: {
        path: FN_LANGUAGE.convertStaticRoute('BILLING_INFO', langList, true),
        element: <Profile />,
        isProtected: true,
      },
      payment: {
        path: FN_LANGUAGE.convertStaticRoute('PAYMENT', langList, true),
        element: <Payment />,
        isProtected: true,
      },
      paymentSuccess: {
        path: FN_LANGUAGE.convertStaticRoute('PAYMENT_SUCCESS', langList, true),
        element: <PaymentSuccess />,
        isProtected: true,
      },
      paymentFailed: {
        path: FN_LANGUAGE.convertStaticRoute('PAYMENT_FAILED', langList, true),
        element: <PaymentFailed />,
        isProtected: true,
      },
      pages: {
        path: FN_LANGUAGE.convertRoute(langList, ':slug'),
        element: <PageNav />,
        isProtected: false,
      },
      loginGoogle: {
        path: FN_LANGUAGE.convertStaticRoute('LOGIN_GOOGLE', langList, true),
        element: <LoginGoogle />,
        isProtected: false,
      },
    }),

    [langList],
  );

  return {
    langList,
    routes,
  };
};

export default useRoutesList;
