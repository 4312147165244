import React, { useEffect } from 'react';

import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { LOCAL_STORAGE } from 'utils/constants';
import { getParams } from 'utils/functions';

const Social: React.FC = () => {
  useEffect(() => {
    const { accessToken, refreshToken } = getParams();
    const pathname = getLocalStorage(LOCAL_STORAGE.PATH_NAME) || '/';
    setLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN, accessToken);
    setLocalStorage(LOCAL_STORAGE.REFRESH_TOKEN, refreshToken);
    window.open(pathname, '_self');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default Social;
