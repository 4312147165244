import { CountriesDataTypes, ProvincesDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getCountriesService = async (): Promise<CountriesDataTypes[]> => {
  const res = await axiosInstance.get('countries');
  return res.data.data;
};

export const getProvincesService = async (id?: string): Promise<ProvincesDataTypes[]> => {
  const res = await axiosInstance.get(`/countries/${id}/provinces`);
  return res.data.data;
};

export const Placeholder = '';
