import React from 'react';
import { Image, Link } from 'tfc-components';

import cloudDiffusion from 'assets/images/cloud-diffusion.svg';
import drAIFront from 'assets/images/dr_ai_front.svg';
import drAIUnder from 'assets/images/dr_ai_under.svg';
import logo from 'assets/images/logo-dr-ai.svg';

interface AuthLayoutProps {
  children?: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children
}) => (
  <div className="t-authLayout">
    <div className="t-authLayout_left">
      <Image extendClasses="t-authLayout_left-logo" imgSrc={logo} ratio={500 / 490} alt="LOGO DIGITAL REVOLUTION AI" />
      <h1 className="t-authLayout_left_title">
        <img className="t-authLayout_left_title-imgUnder animate animate-zoomIn" src={drAIUnder} width={560} height={32} alt="DIGITAL REVOLUTION AI" />
        <img className="t-authLayout_left_title-imgFront animate animate-zoomIn" src={drAIFront} width={473} height={24} alt="DIGITAL REVOLUTION AI" />
      </h1>
    </div>
    <div className="t-authLayout_right">
      <div className="t-authLayout_right_auth">
        <Link href="/" extendClasses="t-authLayout_right_auth-cd">
          <img src={cloudDiffusion} alt="Cloud Diffusion" />
        </Link>
        {children}
      </div>
    </div>
  </div>
);

export default AuthLayout;
