import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  logout: 'logout',
  profile: 'profile',
  timer: 'timer',
  timerActive: 'timerActive',
  arrowDown: 'arrowDown',
  close: 'close',
  order: 'order',
  arrowDownBound: 'arrowDownBound',
  facebook: 'facebook',
  apple: 'apple',
  google: 'google',
  clock: 'clock',
  delete: 'delete',
  deleteDisabled: 'deleteDisabled',
  ai: 'ai',
  aiActive: 'aiActive',
  aiActiveTab: 'aiActiveTab',
  history: 'history',
  historyActive: 'historyActive',
  ordered: 'ordered',
  orderedActive: 'orderedActive',
  profileActive: 'profileActive',
  billingInfo: 'billingInfo',
  billingInfoActive: 'billingInfoActive',
};

export type IconName = keyof typeof iconList;

export type IconSize = '6' | '8' | '9' | '12' | '16' | '20' | '22' | '24' | '32' | '40';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
