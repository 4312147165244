import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getTokenSocketService } from 'services/auth';
import {
  getProfileAction,
  setStatusSocket, updateProcessService
} from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPathName } from 'utils/language';
import socket from 'utils/socket';

const useSocket = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.auth.profile);

  const connectWebSocket = () => {
    try {
      const { protocol, host } = window.location;
      const url = `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/ws`;
      socket(
        url,
        () => getTokenSocketService(),
        {
          onStateChange(status) {
            if (status === 'connected') {
              dispatch(getProfileAction());
            }
            dispatch(setStatusSocket(status));
          },
          onMessage(data) {
            const msg = JSON.parse(data);
            if (msg?.type === 'AIServerResult') {
              dispatch(updateProcessService({
                ...msg.data,
                type: msg.type
              }));
              if (msg.data?.status === 'starting' && pathname !== getPathName('WORKSPACE')) {
                navigate(getPathName('WORKSPACE'));
              }
            }
          },
        }
      );
    } catch (error) {
      // Throw error when the WebSocket connection fails
    }
  };

  useEffect(() => {
    if (profile) {
      connectWebSocket();
    }
  }, [profile?.customerData.email, profile?.customerData.phone]);
};

export default useSocket;
